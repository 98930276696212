// material-ui
import {
  Box,
  Button,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    Stack,
    Typography,
    useMediaQuery
  } from '@mui/material';

// project import
import MainCard from 'components/MainCard';

// assets
import { MailOutlined, PhoneOutlined, SettingOutlined } from '@ant-design/icons';
import ClientDetailsViewModel from '../model/ClientDetailsViewModel';
import { useSelector } from 'react-redux';
import useAuth from 'hooks/useAuth';
import { scopes } from '../../../config/scopes';
import { useTranslation } from 'react-i18next';
import { StringUtils } from 'utils/StringUtils';
import GridTextItem from 'components/statosfera/common/GridTextItem';
import DashboardConfigurationData from 'model/dto/DashboardConfigurationData';
import { useState } from 'react';

export default({model, handleEdit, handleDelete, handleStatus, handleExportCompanyData}) => {
    const context = useAuth();
    const [t, i18n] = useTranslation();
    const configuration = new DashboardConfigurationData(context.user?.configuration, null, DashboardConfigurationData.VIEW_ID_CLIENTS_VIEW);
    const canEdit = model.hasScope(scopes.SCOPE_MITIGATE_EDIT_CLIENT);
    const canDelete = model.hasScope(scopes.SCOPE_MITIGATE_DELETE_CLIENT);
    const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
    const personal = data?.data.personal;
    const socioData = data?.data.socioData;
    const clientType = personal?.clientType;
    const [hiddenFields, setHiddenFields] = useState(configuration.getViewSetting(clientType == DashboardConfigurationData.CLIENT_TYPE_PERSONAL ? "personalHiddenFields" : "businessHiddenFields", []));
    model.setHiddenFields(hiddenFields);
    const defaultCurrency = configuration.getSetting(DashboardConfigurationData.SETTING_DEFAULT_CURRENCY, "HUF");
    const canChangeStatus = model.hasRightToChangeClientStatus(personal?.nextStatuses);

    const onChangeStatus = () => {
      if(!model.hasRightToChangeClientStatus(personal?.nextStatuses)) return;
      handleStatus?.(data);
    }

    const clientTypeName = StringUtils.clientTypeText(t, clientType);
    const language = StringUtils.languageText(t, data?.data?.personal?.language);
    let statusChip = StringUtils.clientStatusChip(t, data?.data?.personal?.status, data?.data?.personal?.adminComment, onChangeStatus);
    let adminComment = model.dataOrEmpty(data?.data?.personal?.adminComment);
    if(adminComment?.includes("status_client_reason")) {
      adminComment = t(adminComment);
    }
    let clientRefusal = "-";
    if(data?.data?.personal?.refusalStatus) {
      clientRefusal = t("refusal_status_"+data?.data?.personal?.refusalStatus.toLowerCase());
    }
    let managerEmail = data?.data?.clientManagerEmail;
    let managerName = data?.data?.clientManagerDisplayName
    let hasCompanyData = data?.data?.hasCompanyData;

    const onEdit = () => {
      handleEdit?.(data);
    }
    const onDelete = () => {
      handleDelete?.(data);
    }

    const handleExport = () => {
      handleExportCompanyData?.(true);
    }
  
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MainCard>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end" sx={{mb: 2}}>
                      <Box sx={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                        fontSize: '0.675rem'
                      }}>
                        {statusChip}
                      </Box>
                    </Stack>
                    <Stack spacing={2.5} alignItems="center">

                      <Stack spacing={0.5} alignItems="center">
                        {personal.displayName && 
                        <Typography variant="h5">{model.limitData(personal.displayName, 18)}</Typography>
                        }
                        {!personal.displayName &&
                        <Box sx={{mt: 1}}></Box>
                        }
                        <Typography color="secondary">{model.limitData(personal.email, 32)}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <List component="nav" aria-label="main mailbox folders" sx={{ py: 0, '& .MuiListItem-root': { p: 0, py: 1 } }}>
                      <ListItem>
                        <ListItemIcon>
                          <SettingOutlined />
                        </ListItemIcon>
                        <ListItemSecondaryAction sx={{left: '24px'}}>
                          <Typography sx={{fontWeight: 'bold'}} color="primary">{clientTypeName}</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <MailOutlined />
                        </ListItemIcon>
                        <ListItemSecondaryAction sx={{left: '24px'}}>
                          <Typography>{model.limitData(personal.email, 32)}</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <PhoneOutlined />
                        </ListItemIcon>
                        <ListItemSecondaryAction sx={{left: '24px'}}>
                          <Typography>{model.dataOrEmpty(personal.phone)}</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={0} direction="row" alignItems="right" sx={{marginTop: 0, marginLeft: 0}} justifyContent="center">
                      <Stack spacing={1} direction={'row'}>
                        <>
                          {canEdit && 
                            <Button variant="shadow" color="primary" onClick={onEdit}>{t('edit')}</Button>
                          }
                          {canChangeStatus && 
                            <Button variant="shadow" color="info" onClick={onChangeStatus}>{t('change_status')}</Button>
                          }
                        </>
                      {canDelete && 
                        <Button variant="shadow" color="error" onClick={onDelete}>{t('delete')}</Button>
                      }
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7} md={8} lg={9}>
              <Stack spacing={2.5}>

                <MainCard title={t('personal_details')} sx={model.isAllFieldHidden(['firstName', 'lastName', 'dateOfBirth', 'gender', 'citizenshipCountry'])}>
                  <List sx={{ py: 0 }}>
                    <ListItem divider={false}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('first_name')} data={model.dataOrEmpty(personal.firstName)} md={6} sx={model.isFieldHidden('firstName')} />
                        <GridTextItem title={t('last_name')} data={model.dataOrEmpty(personal.lastName)} md={6} sx={model.isFieldHidden('lastName')} />
                      </Grid>
                    </ListItem>
                    <ListItem divider={false}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('date_of_birth')} data={model.dataOrEmpty(socioData.dateOfBirth)} md={6} sx={model.isFieldHidden('dateOfBirth')} />
                        <GridTextItem title={t('gender')} data={model.dataOrEmpty(socioData.gender)} md={6} sx={model.isFieldHidden('gender')} />
                      </Grid>
                    </ListItem>

                    <ListItem divider={false} sx={model.isFieldHidden('citizenshipCountry')}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('citizenship')} data={model.dataOrEmpty(socioData.citizenship?.name)} sx={model.isFieldHidden('citizenshipCountry')} />
                      </Grid>
                    </ListItem>
                    
                  </List>
                </MainCard>

                <MainCard title={t('business_entity_details')} sx={model.isAllFieldHidden(['legalName', 'taxNumber'])}>
                  <List sx={{ py: 0 }}>
                    <ListItem divider={false}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('legal_name')} data={model.dataOrEmpty(personal.legalName)} />
                        <GridTextItem title={t('tax_number')} data={model.dataOrEmpty(personal.taxNumber)} />
                      </Grid>
                    </ListItem>
                  </List>
                </MainCard>

                <MainCard title={t('company_data_details')} sx={hasCompanyData ? {display: 'block'} : {display: 'none'}}>
                  <Button variant="shadow" color="primary" onClick={handleExport}>{t('download')}</Button>
                </MainCard>

                <MainCard title={t('payment_details')} sx={model.isAllFieldHidden(['maxAllowedMonthlyPayment', 'monthlyDeduction', 'monthlyAddition'])}>
                  <List sx={{ py: 0 }}>
                    <ListItem divider={false} sx={model.isAllFieldHidden(['maxAllowedMonthlyPayment'])}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('monthly_payment_title')} data={model.formatCurrency(personal.maxAllowedMonthlyPayment, defaultCurrency)} />
                      </Grid>
                    </ListItem>
                    <ListItem divider={false} sx={model.isAllFieldHidden(['monthlyDeduction'])}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('monthly_fixed_deduction', {currency: defaultCurrency})} data={model.formatCurrency(personal.monthlyDeduction, defaultCurrency)} />
                      </Grid>
                    </ListItem>
                    <ListItem divider={false} sx={model.isFieldHidden('monthlyAddition')}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('monthly_fixed_addition', {currency: defaultCurrency})} data={model.formatCurrency(personal.monthlyAddition, defaultCurrency)} />
                      </Grid>
                    </ListItem>
                  </List>
                </MainCard>

                <MainCard title={t('social_data_income')} sx={model.isAllFieldHidden(['taxResidencyCountry', 'netMonthlyIncome'])}>
                  <List sx={{ py: 0 }}>
                    <ListItem divider={false} sx={model.isAllFieldHidden(['taxResidencyCountry'])}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('taxresidency')} data={model.dataOrEmpty(socioData.taxResidency?.name)} sx={model.isFieldHidden('taxResidencyCountry')} />
                      </Grid>
                    </ListItem>
                    <ListItem divider={false} sx={model.isAllFieldHidden(['netMonthlyIncome'])}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('net_monthly_income')} data={model.formatCurrency(socioData.netMonthlyIncome, socioData.incomeCurrency)} sx={model.isFieldHidden('netMonthlyIncome')} />
                      </Grid>
                    </ListItem>
                  </List>
                </MainCard>

                <MainCard title={t('social_data_other')} sx={model.isAllFieldHidden(['educationLevel', 'familyStatus', 'householdSize', 'numberOfDependents'])}>
                  <List sx={{ py: 0 }}>
                    <ListItem divider={false} sx={model.isAllFieldHidden(['educationLevel', 'familyStatus'])}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('education_level')} data={model.getEducationLevel(t, socioData.educationLevel)} sx={model.isFieldHidden('educationLevel')} md={6} />
                        <GridTextItem title={t('family_status')} data={model.getFamilyStatus(t, socioData.familyStatus)} sx={model.isFieldHidden('familyStatus')} md={6} />
                      </Grid>
                    </ListItem>
                    <ListItem divider={false} sx={model.isAllFieldHidden(['householdSize', 'numberOfDependents'])}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('householdsize')} data={model.dataOrEmpty(socioData.householdSize)} sx={model.isFieldHidden('householdSize')} md={6} />
                        <GridTextItem title={t('number_of_dependents')} data={model.dataOrEmpty(socioData.numerOfDependents)} sx={model.isFieldHidden('numberOfDependents')} md={6} />
                      </Grid>
                    </ListItem>
                  </List>
                </MainCard>

                <MainCard title={t('address')} sx={model.isAllFieldHidden(['addressCountry', 'city', 'zip', 'street'])}>
                  <List sx={{ py: 0 }}>
                    <ListItem divider={false}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('address_country')} data={model.dataOrEmpty(socioData.address?.country?.name)} sx={model.isFieldHidden('addressCountry')} md={6} />
                        <GridTextItem title={t('city')} data={model.dataOrEmpty(socioData.address?.city)} sx={model.isFieldHidden('city')} md={6} />
                      </Grid>
                    </ListItem>
                    <ListItem divider={false}>
                      <Grid container spacing={3}>
                        <GridTextItem title={t('zip_code')} data={model.dataOrEmpty(socioData.address?.zip)} sx={model.isFieldHidden('zip')} md={6} />
                        <GridTextItem title={t('street')} data={model.dataOrEmpty(socioData.address?.street)} sx={model.isFieldHidden('street')} md={6} />
                      </Grid>
                    </ListItem>
                  </List>
                </MainCard>

                <MainCard title={t('external_id')} sx={model.isFieldHidden('externalId')}>
                  <Typography color="secondary">
                    {model.dataOrEmpty(personal.externalId)}
                  </Typography>
                </MainCard>

                <MainCard title={t('client_administrator')} sx={model.isFieldHidden('clientManagerEmail')}>
                  <Typography color="secondary">
                    {model.dataOrEmpty(managerName)+' ('+model.dataOrEmpty(managerEmail)+')'}
                  </Typography>
                </MainCard>

                <MainCard title={t('client_refusal_status')}>
                  <Typography color="secondary">
                    {clientRefusal}
                  </Typography>
                </MainCard>

                <MainCard title={t('comment')} sx={model.isFieldHidden('adminComment')}>
                  <Typography color="secondary">
                    {adminComment}
                  </Typography>
                </MainCard>

                <MainCard title={t('language')} sx={model.isFieldHidden('language')}>
                  <Typography color="secondary">
                    {language}
                  </Typography>
                </MainCard>

              </Stack>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    );
}