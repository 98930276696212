import { InfoCircleOutlined, MailOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, Modal, Stack, Typography } from "@mui/material";
import MainCard from "components/MainCard";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StringUtils } from "utils/StringUtils";

export default({model, clientType, chartData, personalData}) => {
    const [t, i18n] = useTranslation();
    const [openScoreModal, setOpenScoreModal] = useState(false);
    const clientTypeName = (clientType == "PERSONAL") ? t('client_type_personal') : t('client_type_business');
    //scoring
    const totalScore = parseInt(chartData?.assessmentData?.normalizedScore);
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
  
      const showScoreModal = () => {
        setOpenScoreModal(true);
      }
      const hideScoreModal = () => {
        setOpenScoreModal(false);
      }
    return(
        <MainCard sx={{minHeight:'360px', background: '#EDFFEF'}}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={2.5} alignItems="center">
              <Stack spacing={0.5} alignItems="center">
                <Avatar alt="Basic"><UserOutlined /></Avatar>
                <Typography variant="h5">{t('risk_scoring')}</Typography>
                <Typography color="secondary"></Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-around" alignItems="center">
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">{(totalScore >= 0) ? totalScore : "-"}/100</Typography>
                <Typography color="secondary" sx={{pl: '4px'}}>{t('score')} <IconButton size={"medium"} sx={{width: '16px', height: '16px', mb: '3px'}} onClick={showScoreModal}><InfoCircleOutlined /></IconButton></Typography>
              </Stack>
              <Divider orientation="vertical" flexItem />
              <Stack spacing={0.5} alignItems="center">
                <Typography variant="h5">{StringUtils.getRiskRating(t, chartData?.assessmentData?.scoreClass)}</Typography>
                <Typography color="secondary">{t('risk')}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <List component="nav" aria-label="main folders" sx={{ py: 0, '& .MuiListItem-root': { p: 0, py: 1 } }}>
            <ListItem>
              <ListItemIcon>
                  <SettingOutlined />
              </ListItemIcon>
              <ListItemSecondaryAction sx={{left: '24px'}}>
                  <Typography sx={{fontWeight: 'bold'}} color="primary">{clientTypeName}</Typography>
              </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
                <ListItemIcon>
                  <UserOutlined />
                </ListItemIcon>
                <ListItemSecondaryAction sx={{left: '24px'}}>
                  <Typography>{model.limitData(chartData?.assessmentData?.personalData?.displayName, 64)}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <MailOutlined />
                </ListItemIcon>
                <ListItemSecondaryAction sx={{left: '24px'}}>
                  <Typography>{model.limitData(personalData?.email, 64)}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Dialog maxWidth="sm" fullWidth onClose={hideScoreModal} open={openScoreModal} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
          <DialogTitle>{t('score_components')}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <Typography variant="h5">{t('score_components_desc')}</Typography>
            <List>
              {chartData?.assessmentData?.hasBankingApiScore &&
              <ListItem>{t('score_component_banking_api')}</ListItem>
              }
              {chartData?.assessmentData?.hasSocioScore &&
              <ListItem>{t('score_component_socio')}</ListItem>
              }
              {chartData?.assessmentData?.hasFraudScore &&
              <ListItem>{t('score_component_fraud')}</ListItem>
              }
            </List>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={hideScoreModal}>
                    {t('close')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </MainCard>        
    );
}