export default() => {
    return {
        login: 'Bejelentkezés',
        logout: 'Kijelentkezés',
        forgot_password: 'Elfelejtett jelszó',
        back_to_login: 'Vissza a bejelentkezéshez',
        send_password_email: 'Jelszó visszaállítás kérése',
        password_reset_success: 'Kérlek nézd meg az e-mail fiókod a jelszó-visszaállító e-mail-hez.',
        dashboard_title: 'Statosfera Rendszer',
        row_per_page: 'Elem az oldalon',
        search: 'Keresés',
        no_data: 'Nincs megjeleníthető adat',
        menu_item_main_menu: 'Menü',
        menu_item_home: 'Főoldal',
        menu_item_settings: 'Beállítások',
        menu_item_access: 'Hozzáférések',
        menu_item_recash: 'Recash',
        menu_item_recash_dashboard: 'Asztal',
        menu_item_recash_settings: 'Beállítások',
        menu_item_recash_transactions: 'Tranzakciók',
        menu_item_mitigate: 'Mitigate',
        menu_item_mitigate_dashboard: 'Asztal',
        menu_item_mitigate_clients: 'Ügyfelek',
        menu_item_profound: 'Profound',
        menu_item_profound_dashboard: 'Asztal',
        menu_item_profound_users: 'Felhasználók',
        save: 'Mentés',
        cancel: 'Mégse',
        add: 'Hozzáad',
        refresh: 'Frissítés',
        group: 'Csoport',
        email: 'E-mail',
        name: 'Név',
        phone: 'Telefonszám',
        created: 'Létrehozva',
        updated: 'Frissítve',
        status: 'Státusz',
        banned: 'Tiltott',
        verified: 'Igazolt',
        active: 'Aktív',
        actions: 'Műveletek',
        unblock: 'Feloldás',
        block: 'Tiltás',
        view: 'Nézet',
        edit: 'Szerkesztés',
        delete: 'Törlés',
        groups: 'Csoportok',
        add_user: 'Új felhasználó',
        access: 'Felhasználók',
        field_is_required: 'Kötelező mező',
        email_is_required: 'E-mail kötelező',
        must_be_valid_email: 'Csak valós e-mail cím lehet',
        last_name_is_required: 'Utónév kötelező',
        first_name_is_required: 'Név kötelező',
        legal_name_is_required: 'Cégnév kötelező',
        phone_is_required: 'Telefonszám kötelező',
        gender_is_required: 'Nem kötelező',
        zip_is_required: 'Irányítószám kötelező',
        city_is_required: 'Város kötelező',
        street_is_required: 'Utca kötelező',
        external_id_is_required: 'Külső azonosító kötelező',
        language: 'Nyelv',
        language_is_required: 'Nyelv kötelező',
        monthly_fixed_deduction_is_required: 'Havi levonás kötelező',
        must_be_a_number: 'Szám kell hogy legyen',
        must_be_min_value: 'Minimum {{min}}',
        must_be_max_value: 'Maximum {{max}}',
        scope_is_required: 'Hozzáférési szint kötelező',
        must_be_min_8_characters: 'A jelszó minimum 8 karakter hosszú kell hogy legyen',
        password_complexity_error: 'A jelszó tartalmazzon legalább egy számot, egy kisbetűt és egy nagybetűt.',
        password_is_required: 'Jelszó kötelező',
        password_match_error: 'A két jelszó nem egyezik',
        password_again_is_required: 'Jelszó újra kötelező',
        phone_format_error: 'A telefonszám nem a megadott formátumú (+36304047324, minimum 10 karakter)',
        user_edited: 'Felhasználó szerkesztve',
        user_added: 'Felhasználó mentve',
        edit_user: 'Felhasználó szerkesztése',
        new_user: 'Új felhasználó',
        enter_email_address: 'E-mail cím',
        password: 'Jelszó',
        enter_password: 'Jelszó',
        password_again: 'Jelszó újra',
        enter_password_again: 'Jelszó újra',
        personal_details: 'Személyes adatok',
        first_name: 'Név',
        enter_first_name: 'Név',
        last_name: 'Utónév',
        enter_last_name: 'Utónév',
        enter_phone: 'Telefonszám (formátum: +36304523912)',
        access_scope: 'Hozzáférési szint',
        other_settings: 'Egyéb beállítások',
        lang_hu: 'Magyar',
        lang_cz: 'Cseh',
        lang_hr: 'Horvát',
        lang_en: 'Angol',
        block_user: 'Tiltás',
        unblock_user: 'Feloldás',
        user_blocked: 'Felhasználó tiltva',
        user_unblocked: 'Felhasználó feloldva',
        block_confirm: 'Biztos, hogy tiltod a felhasználót?<br/><br/><b>FIGYELEM:</b>Ez megakadályozza a felhasználót, hogy bejelentkezzen a Statosfera rendszerbe, de a fiókja nem törlődik.',
        unblock_confirm: 'Biztos, hogy engedélyezed?<br/><br/><b>FIGYELEM:</b>A fiók engedélyezése után az adott felhasználó újra be fog tudni jelentkezni a rendszerbe.',
        delete_user: 'Felhasználó törlése',
        delete_user_confirm: 'Biztos, hogy törölni akarod a felhasználót?<br/><br/><b>FIGYELEM:</b> Ez a művelet véglegesen törli a felhasználót a rendszerből, a művelet nem visszafordítható.',
        user_deleted: 'Felhasználó törölve',
        products: 'Termékek',
        access_rights: 'Hozzáférési jogok',
        add_group: 'Csoport hozzáadása',
        new_group: 'Új csoport',
        edit_group: 'Csoport szerkesztése',
        enter_group_name: 'Csoport neve',
        group_edited: 'Csoport szerkesztve',
        group_added: 'Csoport mentve',
        group_name_length_error: 'A csoport neve legalább 4 karakter legyen',
        group_name_is_required: 'Csoportnév kötelező',
        delete_group: 'Csoport törlése',
        delete_group_confirm: 'Biztos, hogy törlöd a csoportot?<br/><br/><b>FIGYELEM:</b> Ez a művelet véglegesen törli az adott csoportot.',
        group_deleted: 'Csoport törölve',
        members: 'Tagok',
        client_risk_level_excellent: 'Kiváló',
        client_risk_level_safe: 'Jó',
        client_risk_level_medium: 'Elfogadható',
        client_risk_level_low: 'Gyenge',
        client_risk_level_risky: 'Nagyon gyenge',
        clients: 'Ügyfelek',
        add_client: 'Új ügyfél',
        closed: 'Lezárt',
        error: 'Hiba',
        new: 'Új',
        client_type: 'Ügyfél típus',
        last_score: 'Utolsó pontszám',
        client_type_is_required: 'Ügyfél típus kötelező',
        monthly_payment_is_required: 'Fizetési kötelezettség kötelező',
        new_client: 'Új Ügyfél',
        edit_client: 'Ügyfél szerkesztése',
        client_edited: 'Ügyfél szerkesztve',
        client_added: 'Ügyfél mentve',
        business_entity_details: 'Üzleti adatok',
        company_data_details: 'Céges adatok',
        payment_details: 'Fizetési adatok',
        contact_data: 'Kapcsolati adatok',
        address: 'Cím',
        birth_date: 'Születési dátum',
        client_type_personal: 'Magánszemély',
        client_type_business: 'Üzleti ügyfél',
        legal_name: 'Cégnév',
        enter_legal_name: 'Cégnév',
        tax_number: 'Adószám',
        enter_tax_number: 'Adószám',
        enter_phone_number: 'Telefonszám (formátum: +36304523912)',
        social_data_personal: 'Személyes adatok',
        gender: 'Nem',
        gender_male: 'Férfi',
        gender_female: 'Nő',
        gender_other: 'Egyéb',
        citizenship: 'Állampolgárság',
        social_data_income: 'Bevétel',
        taxresidency: 'Adófizetési ország',
        net_monthly_income: 'Havi nettó bevétel',
        enter_net_monthly_income: 'Havi nettó bevétel',
        income_currency: 'Deviza',
        social_data_other: 'Egyéb személyes információk',
        education_level: 'Legmagasabb iskolai végzettség',
        education_level_basic: 'Alapfokú',
        education_level_apprenticeship: 'Szakképzés',
        education_level_secondary: 'Érettségi',
        education_level_college: 'Főiskola',
        education_level_university: 'Egyetem',
        family_status: 'Családi állapot',
        family_status_single: 'Egyedülálló',
        family_status_married: 'Házas',
        family_status_partnership: 'Élettársi kapcsolat',
        family_status_divorced: 'Elvált',
        family_status_widowed: 'Özvegy',
        householdsize: 'Háztartás mérete',
        enter_householdsize: 'Háztartás mérete (emberek száma)',
        number_of_dependents: 'Eltartottak',
        enter_number_of_dependents: 'Eltartottak száma (emberek száma)',
        address_country: 'Ország',
        zip_code: 'Irányítószám',
        enter_zip_code: 'Irányítószám',
        city: 'Város',
        enter_city: 'Város',
        street: 'Utca, házszám, emelet',
        enter_street: 'Utca, házszám, emelet',
        monthly_payment: 'Fizetési kötelezettség ({{currency}})',
        monthly_payment_title: 'Havi kötelezettség',
        enter_monthly_payment: 'Fizetési kötelezettség összege ({{currency}})', 
        monthly_fixed_deduction: 'Havi fix levonás ({{currency}})',
        enter_monthly_deduction: 'Havi fix levonás összege ({{currency}})',
        monthly_fixed_addition: 'Havi fix extra részlet ({{currency}})',
        enter_monthly_addition: 'Havi fix extra részlet összege ({{currency}})',
        monthly_fixed_addition_is_required: 'Havi fix extra részlet kötelező',
        external_id: 'Külső azonosító',
        enter_external_id: 'Külső azonosító (sales, ERP, SAP stb. azonosító)',
        delete_client: 'Ügyfél törlése',
        delete_client_confirm: 'Biztos, hogy törölni akarod az ügyfelet?<br/><br/><b>FIGYELEM:</b> Ez a művelet véglegesen törli az ügyfelet a rendszerből, a művelet nem visszafordítható. A törlés során minden az ügyfélhez kapcsolódó adat törlődik véglegesen.',
        client_deleted: 'Ügyfél törölve',
        bank_access: 'Banki hozzáférés',
        risk_rating: 'Kockázat',
        details: 'Részletek',
        date_of_birth: 'Születési dátum',
        financial_details: 'Pénzügyi adatok',
        comment: 'Megjegyzés',
        client_profile: 'Ügyfél adatai',
        risk_assessment: 'Kockázati profil',
        assessment_history: 'Kockázati előzmények',
        bank_accounts: 'Bankszámlák',
        transactions: 'Tranzakciók',
        communications: 'Kimenő üzenetek',
        events: 'Események',
        logs: 'Logok',
        link_regenerated: 'Link újra létrehozva',
        link_generated: 'Link létrehozva',
        link_generation_error: 'Hiba lépett fel a link létrehozása során',
        data_access_link: 'Hozzáférési link',
        data_access_link_desc: 'Kérlek generálj le egy új hozzáférési linket hogy az ügyfél banki engedélyt tudjon adni.',
        start: 'Kezdés',
        generate_access_link: 'Hozzáférési link létrehozása',
        send_link_to: 'Link küldése...',
        method_of_communication: 'Üzenet módja',
        email_to_address: 'Levél küldése a(z) {{emailAddress}} címre',
        sms_to_number: 'SMS küldése a {{number}} számra',
        copy_to_clipboard: 'Link vágólapra másolása',
        assessment_historical: 'Ez a kockázatkezelési profil régi adatokat tartalmaz',
        show_newest: 'Mutasd a legújabbat',
        bank_access_live: 'A banki hozzáférés élő, de a rendszer még nem kérte le az adatokat, kérlek várj.',
        monthly: 'Havi nézet',
        thirty_days: '30 napos nézed',
        weekly: 'Heti nézet',
        outflow_top_amount: 'Kimenő tranzakciók (top 10 összeg alapján)',
        outflow_top_common: 'Kimenő tranzakciók (top 10 leggyakoribb)',
        outflow_risky: 'Kockázatos tranzakciók (kimenő)',
        nav_transactions: 'NAV tranzakciók',
        bad_nav_transactions: 'Gyanús NAV tranzakciók',
        inflow_top_amount: 'Bejövő tranzakciók (top 10 összeg alapján)',
        inflow_top_common: 'Bejövő tranzakciók (top 10 leggyakoribb)',
        inflow_risky: 'Kockázatos tranzakciók (bejövő)',
        certificate: 'Tanúsítvány',
        from: 'Dátumtól',
        to: 'Dátumig',
        score: 'Pontszám',
        date: 'Dátum',
        show_assessment: 'Megtekintés',
        request_data: 'Új lekérés',
        risk_scoring: 'Kockázati pontszám',
        risk: 'Kockázat',
        bank_access_info: 'Banki hozzáférési információk',
        go_to_bank_access: 'Kérlek válts a banki hozzáférési menüre és hozz létre egy hozzáférési linket az ügyfélnek a folyamat elindításához.',
        client_link_was_generated: 'A hozzáférési link létre lett hozva. Kérlek várj, amíg az ügyfél befejezi a folyamatot',
        go: 'Tovább',
        title: 'Cím',
        type: 'Típus',
        subtype: 'Altípus',
        result: 'Eredmény',
        sent_at: 'Elküldve',
        data: 'Adat',
        session_id: 'Session ID',
        started: 'Elindult',
        finished: 'Befejeződött',
        show_logs: 'Logok',
        transaction_info: 'Közlemény',
        category: 'Kategória',
        amount: 'Összeg',
        account_number: 'Számlaszám',
        holder: 'Számlatulajdonos',
        last_balance: 'Utolsó egyenleg',
        currency: 'Pénznem',
        list_balances: 'Egyenlegek lista',
        balances: 'Egyenlegek',
        period_from: 'Ettől',
        period_to: 'Eddig',
        date_fetched: 'Létrehozás dátuma',
        total_transactions: 'Összes tranzakció (db)',
        net_flow: 'Nettó forgalom',
        client_certificate: 'Ügyfél tanúsítvány',
        count: 'Darabszám',
        sum_amount: 'Teljes összeg',
        average: 'Átlag',
        totals_period: 'Teljes összeg az egész időszakra',
        net_total: 'Nettó teljes összeg',
        outflow: 'Kimenő',
        inflow: 'Bejövő',
        outflow_count: 'Kimenő darab',
        inflow_count: 'Bejövő darab',
        inflow_stats: 'Bejövő statisztika',
        total: 'Teljes',
        median: 'Medián',
        period_totals: 'Időszakra teljes',
        period: 'Időszak',
        scoring_desc_title: 'Pontszámítási metódus leírása',
        scoring_desc_text: 'Lorem ipsum',
        expiring_at: 'Hozzáférés lejár ennyi idő múlva (óra): {{date}}',
        link_expired: 'A hozzáférési link érvénytelen. Kérlek generálj egy újat.',
        client_link: 'Ügyfél hozzáférési link',
        regenerate_link: 'Új hozzáférési link',
        bank_consents: 'Banki hozzáférések',
        bank: 'Bank',
        last_run: 'Utolsó futás',
        last_fail: 'Utolsó hiba',
        banks: 'Bankok',
        logo: 'Logó',
        api_status: 'Banki API állapot',
        id: 'ID',
        log: 'Log',
        session_logs: 'Session logok',
        income_analysis: 'Jövedelem analízis',
        income_type: 'Típus',
        monthly_income: 'Havi jövedelem',
        total_income: 'Teljes jövedelem',
        income_green: 'Zöld',
        income_yellow: 'Sárga',
        income_red: 'Piros',
        income_under_limit: 'Limit alatti',
        transaction_category_debit: 'Terhelés',
        transaction_category_credit: 'Jóváírás',
        transaction_category_unknown: 'Ismeretlen',
        transaction_type_transfer: 'Utalás',
        transaction_type_card: 'Kártya',
        transaction_type_internal: 'Belső',
        transaction_type_refund: 'Visszatérítés',
        transaction_type_atm: 'ATM használat',
        transaction_type_topup: 'Topup kártyával',
        transaction_type_exchange: 'Váltás',
        transaction_type_other: 'Egyéb',
        transaction_type_payment: 'Egyéb befizetés',
        transaction_type_credit_payment: 'Hitel fizetés',
        transaction_type_credit_misc: 'Hitel egyéb',
        transaction_type_investment: 'Befektetés',
        transaction_type_insurance: 'Biztosítás',
        transaction_type_unknown: 'Ismeretlen',
        currency_huf: 'Ft',
        currency_long_huf: 'Forint',
        currency_eur: 'EUR',
        currency_long_eur: 'Euró',
        currency_usd: 'USD',
        currency_long_usd: 'US Dollár',
        currency_gbp: 'GBP',
        currency_long_gbp: 'Font',
        currency_czk: 'CZK',
        currency_long_czk: 'Cseh Korona',
        balance_type_interim_available: 'Időközi elérhető',
        information: 'Információ',
        status_new: 'Új ügyfél',
        status_new_desc: 'Új felvett ügyfél',
        status_social: 'Socio pontszám',
        status_social_desc: 'Az ügyfél adatai alapján elkészült a socio score',
        status_link_generated: 'Link elküldve',
        status_link_generated_desc: 'A rendszer elküldte a hozzáférési linket az ügyfélnek, amivel a banki engedélyt meg tudja adni',
        status_portal_opened: 'Ügyfél portál',
        status_portal_opened_desc: 'Az ügyfél meglátogatta az ügyfél portál oldalát, de még nem fejezte be a banki engedély megadását',
        status_consent_added: 'Banki hozzáférés',
        status_consent_added_desc: 'Az ügyfél sikeresen megadta a banki hozzáférést, de a rendszer még nem futtatta le a kockázati kalkulációt',
        status_in_progress: 'Folyamatban',
        status_in_progress_desc: 'A kockázati pontszám elkészült, minden ügyféladat elérhető',
        status_error: 'Hiba',
        status_error_desc: 'Valamilyen hiba lépett fel a folymat során, a kockázati kalkuláció adatok hiányában nem tudott lefutni (hiányzik a banki engedély vagy a lekérések során hiba lépett fel)',
        status_finished: 'Befejezett',
        status_finished_desc: 'Sikeresen befejezett ügyfél folyamat',
        status_approved: 'Elfogadott',
        status_approved_desc: 'Az ügyfél kockázati profilja alapján üzletileg el lett fogadva',
        status_rejected: 'Elutasítva',
        status_rejected_desc: 'Az ügyfél kockázati profilja alapján üzletileg vissza lett utasítva',
        status_closed: 'Lezárt',
        status_closed_desc: 'Lezárt sikertelen ügyfél folyamat, pl. szerződéstől elállt, nem elérhető stb.',
        consent_status_no_data: 'n.a.',
        consent_status_in_progress: 'Folyamatban',
        consent_status_ok: 'Sikeres',
        client_external_id: 'Ügyfél külső azonosító',
        assessment_id: 'Belső sorszám',
        print: 'Nyomtatás',
        access_settings: 'Hozzáférési beállítások',
        request_data_type: 'Adatlekérés típusa',
        request_data_desc: 'Helyi adatok alapján minden órában egyszer kérhető új profil. Banki lekérés esetén két profil között minimum 6 óra el kell hogy teljen. Az új profil az éppen aktuálisan mentett ügyféladatok (pl. törlesztő) alapján készül el.',
        request_data_type_local: 'Új kockázati profil helyi adatokból',
        request_data_type_local_desc: 'Új kockázati profil készítése a már meglévő, a szerveren elmentett adatokból. A kockázati profil alapja a meglevő adatok mellett az ügyfél aktuálisan elmentett beállításai (pl. havi törlesztő) lesznek. Ez a folyamat 1-5 másodpercig tarthat az adatok mennyiségétől függően.',
        request_data_type_api: 'Új kockázati profil banki adatokból',
        request_data_type_api_desc: 'Új kockázati profil készítése az ügyfél által megadott hozzájárulás(ok) alapján a legfrissebb, utolsó 90 napnyi banki adatból. A kockázati profil alapja az új adatok mellett az ügyfél aktuálisan elmentett beállításai (pl. havi törlesztő) lesznek. Ez a folyamat akár 30 másodpercig is tarthat a megadott engedélyek számától és az adatok mennyiségétől függően.',
        data_request_success: 'Kérés mentve. Frissítés folyamatban.',
        data_request_success_api: 'Kérés mentve. Kérlek várj egy percet és töltsd újra az oldalt',
        all_users: 'Összes felhasználó',
        own_users: 'Saját felhasználó',
        all_clients: 'Összes ügyfél',
        own_clients: 'Saját ügyfél',
        welcome: 'Hello',
        logged_in_as: 'Bejelentkezve',
        system_name: 'Rendszer',
        system_version: 'Verziószám',
        mitigate_statistics: 'Mitigate statisztika',
        new_clients: 'Új ügyfél',
        processed_clients: 'Folyamatban ügyfél',
        last_client_added: 'Utolsó ügyfél',
        status_required: 'Hiba',
        status_auth_error: 'Hiba',
        status_pending: 'Függőben',
        bank_scans: 'Manuális scan',
        status_new_scan: 'Új',
        status_uploaded_scan: 'Feltöltve',
        status_converted_scan: 'Konvertált',
        status_processed_scan: 'Feldolgozott',
        status_deleted_scan: 'Törölt',
        label: 'Címke',
        add_scan: 'Új scan',
        delete_scan: 'Scan törlése',
        delete_scan_confirm: 'Biztos, hogy törölni akarod a manuális scan-t?<br/><br/><b>FIGYELEM:</b> Ez a művelet véglegesen törli a scan-t és a felvitt tranzakciókat a rendszerből, a művelet nem visszafordítható.',
        scan_deleted: 'Scan törölve',
        scan_details: 'Scan részletei',
        scan_basic_data: 'Alap adatok',
        scan_files: 'File-ok',
        file_name: 'File név',
        file_size: 'File mérete',
        scan_upload_status: 'Feltöltési állapot',
        scan_conversion_status: 'Konvertálás állapot',
        scan_processed_status: 'Feldolgozási állapot',
        scan_processed_transactions: 'Feldolgozott tranzakciók',
        status_scan_uploaded_ok: 'Feltöltve',
        status_scan_uploaded_error: 'Feltöltési hiba',
        status_scan_converted_none: 'Nincs',
        status_scan_converted_ok: 'Konvertálva',
        status_scan_converted_error: 'Konvertálási hiba',
        status_scan_converted_warn: 'Konvertálva (figyelmeztetésekkel)',
        status_scan_processed_none: 'Nincs',
        status_scan_processed_ok: 'Feldolgozva',
        status_scan_processed_error: 'Feldolgozási hiba',
        status_scan_processed_warn: 'Feldolgozva (figyelmeztetésekkel)',
        new_scan: 'Új scan',
        scan_settings: 'Scan beállítások',
        enter_scan_label: 'Címke',
        scan_file: 'File',
        enter_scan_file: 'File',
        label_is_required: 'Címke kötelező mező',
        bankid_is_required: 'Bank kötelező mező',
        file_is_required: 'File kötelező',
        file_is_not_valid_type: 'Nem érvényes file. Csak PDF kiterjesztésű file elfogadott',
        file_too_large: 'A kiválaszott file túl nagy méretű. Maximum méret: 50 MB',
        scan_added: 'Scan hozzáadva. Kérlek várj amíg a konverziós folyamat lefut a háttérben.',
        scan_data_status: 'Scan feltöltési státusz',
        scan_id: 'Scan ID',
        close: 'Bezárás',
        number_of_errors: 'Hibák',
        number_of_warnings: 'Figyelmeztetések',
        show_errors: 'Hibalista',
        show_warnings: 'Figyelmeztetések listája',
        scan_logs: 'Scan logok',
        scan_statement_id: 'Kivonat sorszám',
        scan_statement_created_at: 'Kivonat dátuma',
        scan_statement_from: 'Kivonat periódus kezdete',
        scan_statement_to: 'Kivonat periódus vége',
        export_clients: 'Export',
        export_link_created: 'Letöltési link létrehozva',
        download: 'Letöltés',
        link_valid_until: 'Letöltési link érvényes',
        loading: 'Töltés',
        statistics: 'Statisztika',
        bank_tutorial: 'Folyamat leírása',
        client_already_exists_with_email: 'Ezzel az e-mail címmel már létezik ügyfél a rendszerben',
        client_status_is_required: 'A státusz kötelező',
        change_client_status: 'Ügyfélstátusz szerkesztése',
        enter_admin_comment: 'Adminisztrátor megjegyzése (maximum 2048 karakter)',
        admin_comment: 'Adminisztrátor megjegyzés',
        status_changed: 'Ügyfél státusz mentve',
        status_client_reason_did_not_want_consent: 'Nem akart banki engedélyt adni',
        status_client_reason_could_not_give_consent_missing_login_data: 'Nem tudott banki engedélyt adni (nem tudta a belépést)',
        status_client_reason_could_not_give_consent_bank_error: 'Nem tudott banki engedélyt adni (banki vagy egyéb hiba)',
        status_client_reason_did_not_want_contract: 'Elállt a szerződéstől',
        status_client_reason_other: 'Egyéb',
        change_status: 'Státusz',
        status_types_all: 'Mindenki',
        status_types_active: 'Aktív ügyfél',
        status_types_inactive: 'Inaktív ügyfél',
        status_types_new: 'Új ügyfél',
        status_types_link_generated: 'Link elküldve',
        status_types_portal_opened: 'Portál',
        status_types_consent_added: 'Banki hozzájárulás',
        status_types_in_progress: 'Folyamatban',
        status_types_approved: 'Elfogadva',
        status_types_rejected: 'Elutasítva',
        status_types_error: 'Hiba',
        status_types_finished: 'Befejezett',
        status_types_closed: 'Lezárt',
        client_administrator: 'Ügyfél kezelője',
        user_profile: 'Saját profil',
        profile_basic: 'Adatok',
        profile_personal: 'Szerkesztés',
        profile_change_password: 'Jelszóváltoztatás',
        profile_settings: 'Beállítások',
        language_hu: 'Magyar',
        language_en: 'English',
        profile_updated: 'Profil frissítve',
        change_password: 'Jelszóváltoztatás',
        old_password: 'Jelenlegi jelszó',
        old_password_is_required: 'A régi jelszó megadása kötelező',
        new_password: 'Új jelszó',
        new_password_again: 'Új jelszó újra',
        new_password_is_required: 'Új jelszó megadása kötelező',
        new_password_again_is_required: 'Új jelszó újra megadása kötelező',
        password_complexity_desc: 'A jelszó legalább 8 karakter hosszú kell, hogy legyen, és tartalmaznia kell legalább egy kisbetűt, egy nagybetűt és egy számot',
        error_old_password_no_match: 'A megadott jelenlegi jelszó nem megfelelő, kérlek próbáld újra',
        password_changed: 'Jelszó sikeresen megváltoztatva',
        profile_events: 'Események',
        profile_communications: 'Üzenetek',
        access_scope_mitigate: 'Hozzáférési szint (Mitigate)',
        access_scope_recash: 'Hozzáférési szint (RECASH)',
        access_scope_profound: 'Hozzáférési szint (Profound)',
        at_least_once_template_required: 'Minimum egy hozzáférési szint kötelező',
        dashboard_user: 'Felhasználó',
        other: 'Egyéb',
        last_login: 'Utolsó bejelentkezés ideje',
        company: 'Szervezet',
        business_group: 'Szervezeti csoport',
        business_manager: 'Ügyintéző',
        access_groups: 'Hozzáférési szint(ek)',
        access_scopes: 'Hozzáférési jogok',
        update: 'Frissítés',
        export_users: 'Export',
        score_components: 'Score részek',
        score_components_desc: 'A végső pontszámhoz felhasznált score komponensek:',
        score_component_banking_api: 'Banki adat',
        score_component_fraud: 'Fraud adat',
        score_component_socio: 'Socio adat',
        score_component_company: 'Céges adatok',
        client_refusal_status: 'Consent megadás megtagadásának indoka',
        refusal_status_bank_not_available: 'Ügyfél bankja nem elérhető',
        refusal_status_consent_refused: 'Ügyfél nem akart consent-et adni',
        refusal_status_other: 'Egyéb',
        export_client_company_data: 'Céges adatok export',

        //Scopes
        product_mitigate: 'Mitigate',
        product_profound: 'Profound',
        product_recash: 'Recash',
        scope_mitigate_admin: 'Mitigate Felhasználó',
        scope_mitigate_manage_client: 'Ügyfelek kezelése',
        scope_mitigate_manage_all_clients: 'Összes ügyfél kezelése',
        scope_mitigate_manage_own_clients: 'Saját ügyfelek kezelése',
        scope_mitigate_view_own_client_data: 'Saját ügyfél adatai láthatóak',
        scope_mitigate_view_all_client_data: 'Összes ügyfél adatai láthatóak',
        scope_mitigate_view_own_client_data_details: 'Saját ügyfél részletes adatai láthatóak',
        scope_mitigate_view_all_client_data_details: 'Összes ügyfél részletes adatai láthatóak',
        scope_mitigate_edit_client: 'Ügyfél szerkesztése',
        scope_mitigate_edit_all_clients: 'Összes ügyfél szerkesztése',
        scope_mitigate_edit_own_clients: 'Saját ügyfelek szerkesztése',
        scope_mitigate_change_status_client: 'Ügyfél státusz módosítása',
        scope_mitigate_delete_client: 'Ügyfél törlése',
        scope_mitigate_delete_all_clients: 'Összes ügyfél törlése',
        scope_mitigate_delete_own_clients: 'Saját ügyfelek törlése',
        scope_mitigate_export_clients: 'Ügyfél lista export',
        scope_mitigate_can_approve_client: "Ügyfélstátusz állítás Elfogadvára",
        scope_mitigate_can_reject_client: "Ügyfélstátusz állítás Elutasításra",
        scope_mitigate_can_finish_client: "Ügyfélstátusz állítás Befejezettre",
        scope_mitigate_can_close_client: "Ügyfélstátusz állítás Lezártra",
        scope_mitigate_assessment_view: 'Kockázati adatok nézet',
        scope_mitigate_assessment_history: 'Kockázati előzmények nézed',
        scope_mitigate_bank_accounts: 'Bankszámlák nézet',
        scope_mitigate_bank_account_details: 'Bankszámlák részletei',
        scope_mitigate_transactions: 'Banki tranzakciók nézed',
        scope_mitigate_scans: 'Manuális scan nézed',
        scope_mitigate_manage_scans: 'Manuális scan kezelés',
        scope_mitigate_bank_access: 'Banki hozzáférés nézed',
        scope_mitigate_monitoring: 'Ügyfél monitoring nézed',
        scope_mitigate_manage_monitoring: 'Ügyfél monitoring kezelés',
        scope_mitigate_communications: 'Kimenő üzenetek nézet',
        scope_mitigate_events: 'Ügyfél események nézed',
        scope_mitigate_logs: 'Rendszer logok nézet',
        scope_mitigate_request_data: 'Új kockázatkezelési eredmény kérése',
        scope_mitigate_assessment_view_all: 'Minden kockázatkezelési adat látható',
        scope_mitigate_assessment_view_summary_stats: 'Kockázatkezelési összefoglaló statisztika',
        scope_mitigate_assessment_view_income: 'Kockázatkezelési jövedelemanalízis',
        scope_mitigate_assessment_view_fraud: 'Kockázatkezelési fraud adatok',
        scope_mitigate_assessment_view_accounts: 'Kockázatkezelési számlák',
        scope_mitigate_assessment_view_stats: 'Kockázatkezelési statisztikák',
        scope_mitigate_assessment_view_transactions: 'Kockázatkezelési tranzakciók',
        scope_mitigate_assessment_view_certificate: 'Tanusítvány látható',
        scope_mitigate_assessment_view_export: 'Kockázatkezelési adatok export',
        scope_mitigate_score_details: 'Részletes scoring adatok',
        scope_mitigate_manage_users: 'Felhasználók kezelése',
        scope_mitigate_delete_users: 'Felhasználók törlése',
        scope_mitigate_manage_all_users: 'Minden felhasználó kezelése',
        scope_mitigate_delete_all_users: 'Minden felhasználó törlése',
        scope_mitigate_manage_own_users: 'Saját felhasználók kezelése',
        scope_mitigate_delete_own_users: 'Saját felhasználók törlése',
        scope_mitigate_manage_groups: 'Csoportok kezelése',
        scope_mitigate_delete_groups: 'Csoportok törlése',
        account_holder_name_check_title: 'Számlatulajdonos ellenőrzés',
        account_holder_name_check_desc: 'A számlatulajdonos neve megegyezik az ügyfél nevével',
        account_holder_name_check_desc_nomatch: 'A számlatulajdonos neve NEM EGYEZIK MEG az ügyfél nevével',
        account_holder_name_check_desc_unknown: 'A számlatulajdonos név egyezést nem tudtuk megállapítani',

        country_hungary: 'Magyarország',
        country_czech: 'Csehország',
        country_croatia: 'Horvátország',
        country_noneu: 'Nem EU-s'
      }
}