import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addClient, editClient, generateClientAccessToken, getClientData, getClientDetails, getClients, getClientTransactions, getClientAssessmentHistory, deleteClient, getClientAccounts, getClientEvents, getClientSessions, getClientSessionLogs, getClientCommunications, requestAssessment, getClientScans, deleteScan, getClientScanDetails, addClientScan, getClientScanDataDetails, exportClients, changeClientStatus, getClientDetailsEdit, exportClientCompanyData } from '_api';

const getClientsRequest = createAsyncThunk("clients/list", async (payload) => {
    const result = await getClients(payload);
    return result.data;
});

const exportClientsRequest = createAsyncThunk("clients/export", async (payload) => {
    const result = await exportClients(payload);
    return result.data;
});

const addClientRequest = createAsyncThunk("clients/add", async (payload) => {
    const data = Object.assign({}, payload);
    const token = data.token;
    delete data.token;
    const result = await addClient(token, data);
    return result.data;
});

const editClientRequest = createAsyncThunk("clients/edit", async (payload) => {
    const data = Object.assign({}, payload);
    const clientId = data.id;
    delete data.id;
    const token = data.token;
    delete data.token;
    const result = await editClient(token, clientId, data);
    return result.data;
});

const deleteClientRequest = createAsyncThunk("clients/delete", async (payload) => {
    const clientId = payload.id;
    const token = payload.token;
    const result = await deleteClient(token, clientId);
    return result.data;
});

const changeClientStatusRequest = createAsyncThunk("clients/status", async (payload) => {
    const data = Object.assign({}, payload);
    const clientId = data.id;
    delete data.id;
    const token = data.token;
    delete data.token;
    const result = await changeClientStatus(token, clientId, data);
    return result.data;
});

const getClientDetailsRequest = createAsyncThunk("clients/details", async (payload) => {
    const result = await getClientDetails(payload);
    return result.data;
});

const getClientDetailsEditRequest = createAsyncThunk("clients/details/edit", async (payload) => {
    const result = await getClientDetailsEdit(payload);
    return result.data;
});

const getClientDataRequest = createAsyncThunk("clients/data", async (payload) => {
    const result = await getClientData(payload);
    return result.data;
});

const getClientAccountsRequest = createAsyncThunk("clients/accounts", async (payload) => {
    const result = await getClientAccounts(payload);
    return result.data;
});

const getClientTransactionsRequest = createAsyncThunk("clients/transactions", async (payload) => {
    const result = await getClientTransactions(payload);
    return result.data;
});

const getClientScansRequest = createAsyncThunk("clients/scans", async (payload) => {
    const result = await getClientScans(payload);
    return result.data;
});

const getClientScanDetailsRequest = createAsyncThunk("clients/scans/details", async (payload) => {
    const result = await getClientScanDetails(payload);
    return result.data;
});

const addClientScanRequest = createAsyncThunk("clients/scans/add", async (payload) => {
    const result = await addClientScan(payload);
    return result.data;
});

const getClientScanDataDetailsRequest = createAsyncThunk("clients/scans/data/details", async (payload) => {
    const result = await getClientScanDataDetails(payload);
    return result.data;
});

const deleteClientScanRequest = createAsyncThunk("clients/scans/delete", async (payload) => {
    const clientId = payload.clientId;
    const scanId = payload.scanId;
    const token = payload.token;
    const result = await deleteScan(token, clientId, scanId);
    return result.data;
});

const getClientAssessmentHistoryRequest = createAsyncThunk("clients/assessments", async (payload) => {
    const result = await getClientAssessmentHistory(payload);
    return result.data;
});

const getClientEventsRequest = createAsyncThunk("clients/events", async (payload) => {
    const result = await getClientEvents(payload);
    return result.data;
});

const getClientCommunicationsRequest = createAsyncThunk("clients/communications", async (payload) => {
    const result = await getClientCommunications(payload);
    return result.data;
});

const getClientSessionsRequest = createAsyncThunk("clients/sessions", async (payload) => {
    const result = await getClientSessions(payload);
    return result.data;
});

const getClientSessionLogsRequest = createAsyncThunk("clients/session_logs", async (payload) => {
    const result = await getClientSessionLogs(payload);
    return result.data;
});

const generateClientAccessTokenRequest = createAsyncThunk("clients/token", async (payload) => {
    const result = await generateClientAccessToken(payload);
    return result.data;
});

const requestAssessmentDataRequest = createAsyncThunk("clients/assessment_request", async (payload) => {
    const data = Object.assign({}, payload);
    const clientId = data.id;
    delete data.id;
    const token = data.token;
    delete data.token;
    const result = await requestAssessment(token, clientId, data);
    return result.data;
});

const exportCompanyDataRequest = createAsyncThunk("clients/company_data_request", async (payload) => {
    const data = Object.assign({}, payload);
    const clientId = data.id;
    delete data.id;
    const token = data.token;
    delete data.token;
    const result = await exportClientCompanyData(token, clientId, data);
    return result.data;
});

const client = createSlice({
    name: 'client',
    initialState: {
        clients: null,
        selectedClient: null,
        selectedClientData: null,
        clientPeriodType: "MONTHLY",
        clientChartData: null,
        clientAccounts: null,
        clientTransactions: null,
        clientScans:null,
        clientAssessments: null,
        clientEvents: null,
        clientCommunications: null,
        clientSessions: null,
        clientSessionLogs: null,
        clientLink: null
    },
    reducers: {
        selectClient: (state, action) => {
            state.selectedClient = action.payload;
        },
        selectPeriodType: (state, action) => {
            state.clientPeriodType = action.payload;
        },
        setClientLink: (state, action) => {
            state.clientLink = action.payload;
        },
        resetClient: (state, action) => {
            state.selectedClient = null;
            state.selectedClientData = null;
            state.clientChartData = null;
            state.clientAccounts = null;
            state.clientTransactions = null;
            state.clientScans = null;
            state.clientAssessments = null;
            state.clientEvents = null;
            state.clientCommunications = null;
            state.clientSessions = null;
            state.clientSessionLogs = null;
            state.clientLink = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getClientsRequest.fulfilled, (state, action) => {
            console.log("Get clients result: ", action.payload);
            state.clients = action.payload;
        });
        builder.addCase(exportClientsRequest.fulfilled, (state, action) => {
            console.log("Export clients result: ", action.payload);
        });
        builder.addCase(getClientDetailsRequest.fulfilled, (state, action) => {
            console.log("Get client details result: ", action.payload);
            if(action.payload.success) {
                state.selectedClientData = action.payload;
            }
        });
        builder.addCase(getClientDataRequest.fulfilled, (state, action) => {
            console.log("Get client data result: ", action.payload);
            state.clientChartData = action.payload;
        });
        builder.addCase(getClientAccountsRequest.fulfilled, (state, action) => {
            console.log("Get client accounts result: ", action.payload);
            state.clientAccounts = action.payload;
        });
        builder.addCase(getClientTransactionsRequest.fulfilled, (state, action) => {
            console.log("Get client transactions result: ", action.payload);
            state.clientTransactions = action.payload;
        });
        builder.addCase(getClientScansRequest.fulfilled, (state, action) => {
            console.log("Get client scans result: ", action.payload);
            state.clientScans = action.payload;
        });
        builder.addCase(getClientScanDetailsRequest.fulfilled, (state, action) => {
            console.log("Get client scan details result: ", action.payload);
        });
        builder.addCase(addClientScanRequest.fulfilled, (state, action) => {
            console.log("Add client scan result: ", action.payload);
        });
        builder.addCase(getClientScanDataDetailsRequest.fulfilled, (state, action) => {
            console.log("Get client scan data details result: ", action.payload);
        });
        builder.addCase(deleteClientScanRequest.fulfilled, (state, action) => {
            console.log("Delete client scan result: ", action.payload);
        });
        builder.addCase(getClientAssessmentHistoryRequest.fulfilled, (state, action) => {
            console.log("Get client assessment history result: ", action.payload);
            state.clientAssessments = action.payload;
        });
        builder.addCase(getClientEventsRequest.fulfilled, (state, action) => {
            console.log("Get client events result: ", action.payload);
            state.clientEvents = action.payload;
        });
        builder.addCase(getClientCommunicationsRequest.fulfilled, (state, action) => {
            console.log("Get client communications result: ", action.payload);
            state.clientCommunications = action.payload;
        });
        builder.addCase(getClientSessionsRequest.fulfilled, (state, action) => {
            console.log("Get client sessions result: ", action.payload);
            state.clientSessions = action.payload;
        });
        builder.addCase(getClientSessionLogsRequest.fulfilled, (state, action) => {
            console.log("Get client sessions logs result: ", action.payload);
            state.clientSessionLogs = action.payload;
        });
        builder.addCase(generateClientAccessTokenRequest.fulfilled, (state, action) => {
            console.log("Get client token result: ", action.payload);
        });
        builder.addCase(requestAssessmentDataRequest.fulfilled, (state, action) => {
            console.log("Request assessment result: ", action.payload);
        });
        builder.addCase(exportCompanyDataRequest.fulfilled, (state, action) => {
            console.log("Export company data result: ", action.payload);
        });
    }
});

export default client.reducer;

export {
    getClientsRequest,
    exportClientsRequest,
    addClientRequest,
    editClientRequest,
    deleteClientRequest,
    changeClientStatusRequest,
    getClientDetailsRequest,
    getClientDetailsEditRequest,
    getClientDataRequest,
    getClientAccountsRequest,
    getClientTransactionsRequest,
    getClientScansRequest,
    getClientScanDetailsRequest,
    addClientScanRequest,
    getClientScanDataDetailsRequest,
    deleteClientScanRequest,
    getClientAssessmentHistoryRequest,
    getClientEventsRequest,
    getClientCommunicationsRequest,
    getClientSessionsRequest,
    getClientSessionLogsRequest,
    generateClientAccessTokenRequest,
    requestAssessmentDataRequest,
    exportCompanyDataRequest
}

export const { selectClient, selectPeriodType, setClientLink, resetClient } = client.actions;