import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { format, parseISO } from "date-fns";

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';

import { openSnackbar } from 'store/reducers/snackbar';


import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

import { ConnectedFocusError } from 'focus-formik-error'
import { addClientScanRequest } from 'store/reducers/client';
import { useEffect, useState } from 'react';

// constant
const getInitialValues = (customer) => {
  const newCustomer = {
    bankId: '',
    scanFile: ''
  };
  return newCustomer;
};

const AddScan = ({ model, customer, clientId, onCancel, onSaved }) => {
  const theme = useTheme();
  const context = useAuth();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const [banks, setBanks] = useState();
  const isCreating = !customer;

  useEffect(() => {
    if(!banks) {
      model.fetchBankList(dispatch).unwrap().then(res => {
        if(res.success && res.data?.banks) {
          setBanks(res.data.banks);
        }
      });
    }
  }, [clientId]);

  const validFileExtensions = { pdf: ['pdf'] };

  const maxAllowedSize = 2*52428800; //100 MB

  const isValidFileType = (fileName, fileType) => {
    return fileName && validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1;
  }

  const ClientSchema = Yup.object().shape({
    bankId: Yup.number().required(t('bankid_is_required')),
    scanFile: Yup.mixed()
      .required(t('file_is_required'))
      .test("is-valid-type", t('file_is_not_valid_type'), value => value && isValidFileType(value.toLowerCase(), "pdf"))
  });

  const formik = useFormik({
    initialValues: getInitialValues(customer),
    validationSchema: ClientSchema,
    onSubmit: (values, { setSubmitting }) => {
        const fileInput = document.getElementById('scan-file').files[0];
        values.token = context.user?.token;
        values.clientId = clientId;
        values.fileInput = fileInput;
        values.fileName = fileInput.name;
        values.label = "scan-"+clientId+"-"+values.bankId+"-"+format(new Date(), "yyyyMMddHHii");
        if(fileInput.size > maxAllowedSize) {
          dispatch(openSnackbar({open: true, message: t('file_too_large'), variant: 'alert', alert: {color: 'error'}, close: false}));
          return;
        }
        try {
            dispatch(addClientScanRequest(values)).unwrap().then(res => {
              setSubmitting(false);
              if(res.success) {
                dispatch(openSnackbar({open: true, message: t('scan_added'), variant: 'alert', alert: {color: 'success'}, close: false}));
                if(onSaved) {
                  onSaved(res);
                }
                onCancel();
              }
              else {
                dispatch(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
              }
            });
        } catch (error) {
          console.error(error);
        }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>{t('new_scan')}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            <ConnectedFocusError />
            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Stack spacing={1.25}>
                    <Divider textAlign="left"><Chip label={t('scan_settings')} /></Divider>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel id="bankId-label" htmlFor="bankId">{t('bank')}</InputLabel>
                      <Select
                        labelId="bankId-label"
                        id="bankId"
                        label="bankId"
                        {...getFieldProps('bankId')}
                        error={Boolean(touched.bankId && errors.bankId)}
                      >
                        {banks?.filter(i => i.status == 'ACTIVE' && i.scanStatus == 'ACTIVE').map(item => {
                          return <MenuItem key={'bankId_'+item.id} value={item.id}>{item.name} ({item.region})</MenuItem>
                        })}
                      </Select>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Stack spacing={1.25}>
                    <Divider textAlign="left"><Chip label={t('scan_file')} /></Divider>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Stack spacing={1.25}>
                    <InputLabel htmlFor="scan-file">{t('scan_file')}</InputLabel>
                    <TextField
                        fullWidth
                        type="file"
                        id="scan-file"
                        placeholder={t('enter_scan_file')}
                        {...getFieldProps('scanFile')}
                        error={Boolean(touched.scanFile && errors.scanFile)}
                        helperText={touched.scanFile && errors.scanFile}
                    />
                    </Stack>
                </Grid>

            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={onCancel}>
                    {t('cancel')}
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    {t('add')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
};

AddScan.propTypes = {
  customer: PropTypes.any,
  onCancel: PropTypes.func
};

export default AddScan;
