import { FundViewOutlined, InfoCircleOutlined, LinkOutlined } from "@ant-design/icons";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";
import ScrollX from "components/ScrollX";
import ReactTable from "components/statosfera/table/ReactTable";
import useAuth from "hooks/useAuth";
import PaginationData from "model/dto/PaginationData";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ClientDetailsViewModel from "../model/ClientDetailsViewModel";
import { config as apiConfig, selectedProfile } from '../../../config/config';
import { openSnackbar } from 'store/reducers/snackbar';

export default({clientId, setSelectedTab, setSelectedAssessment}) => {
    const dispatcher = useDispatch();
    const context = useAuth();
    const [t, i18n] = useTranslation();

    const data = useSelector(state => ClientDetailsViewModel.loadFromStore(state));
    const model = new ClientDetailsViewModel(null, context.user);
    const [loaded, setLoaded] = useState(false);
    const [filter, setFilter] = useState(model.getFieldFilter(10, "createdAt"));
    const [dialogOpen, setDialogOpen] = useState(false);
    const [requestType, setRequestType] = useState("LOCAL");
    const [isSubmitting, setSubmitting] = useState(false);
    useEffect(() => {
        if(data) {
          setLoaded(false);
          model.fetchClientAssessmentHistory(dispatcher, clientId, filter).unwrap().then(res => {
            setLoaded(true);
          });
        }
      }, [data, dispatcher, clientId, filter]);
    if(!data) {
        return "";
    }
    const columns = useMemo(
        () => [
          {
            Header: t('id'),
            accessor: 'id'
          },
          {
            Header: t('certificate'),
            accessor: 'certificate'
          },
          {
            Header: t('from'),
            accessor: 'from'
          },
          {
            Header: t('to'),
            accessor: 'to'
          },
          {
            Header: t('score'),
            accessor: 'score'
          },
          {
            Header: t('score_components'),
            disableSortBy: true,
            accessor: 'scoreComponents'
          },
          {
            Header: t('created'),
            accessor: 'createdAt'
          },
          {
            Header: t('actions'),
            disableSortBy: true,
            accessor: 'actions'
          }
        ]);
    const handlePageChange = (data) => {
        filter.page = data;
        setFilter(PaginationData.from(filter));
    }

    const handlePageSizeChange = (data) => {
        filter.limit = data;
        setFilter(PaginationData.from(filter));
    }

    const handleSort = (id, desc) => {
        let sameDir = (filter.order == "desc" && desc) || (filter.order == "asc" && !desc);
        if(filter.orderBy == id && sameDir) {
            return;
        }
        filter.orderBy = id;
        filter.order = (desc) ? "desc" : "asc";
        setFilter(PaginationData.from(filter));
    }
    const handleSelectAssessment = (item) => {
      setSelectedAssessment(item);
      setSelectedTab("tab-assessment");
    } 
    const assessmentData = useSelector(state => state.client.clientAssessments);
    if(!assessmentData) {
      return "";
    }
    let items = [];
    assessmentData?.data?.items?.forEach(i => {
        const score = parseInt(i.normalizedScore)+"/100";
        let certificate = '-';
        if(i.certificateId) {
            const certificateUrl = apiConfig[selectedProfile].PUBLIC_API_BASE_URL+"/mitigate/certificate/hu/"+i.certificateId;
            certificate = <>{i.certificateId}<IconButton href={certificateUrl} target="_blank" rel="noopener noreferrer" color="secondary"><LinkOutlined /></IconButton></>
        }
        let scoreComponents = [];
        if(i.hasBankingApiScore) {
          scoreComponents.push(t('score_component_banking_api'));
        }
        if(i.hasFraudScore) {
          scoreComponents.push(t('score_component_fraud'));
        }
        if(i.hasSocioScore) {
          scoreComponents.push(t('score_component_socio'))
        }
        if(i.hasCompanyScore) {
          scoreComponents.push(t('score_component_company'))
        }
        items.push({
            id: i.id,
            from: model.formatShortDate(i.from),
            to: model.formatShortDate(i.to),
            certificate: certificate,
            score: score,
            scoreComponents: scoreComponents.join(","),
            createdAt: model.formatShortDate(i.createdAt),
            actions: <IconButton color="secondary" onClick={() => handleSelectAssessment(i)}><Tooltip title={t('show_assessment')}><FundViewOutlined /></Tooltip></IconButton>
        });
    });
    const sortBy = { id: 'createdAt', desc: true };
    //Request assessment
    const canRequestAssessmentLocal = data?.data?.canRequestAssessmentLocal;
    const canRequestAssessmentApi = data?.data?.canRequestAssessmentApi;
    const canRequestAssessment = canRequestAssessmentLocal || canRequestAssessmentApi;
    const requestData = () => {
      setDialogOpen(true);
    }
    const handleRequestTypeChange = (event) => {
      setRequestType(event.target.value);
    }
    const handleRequestDataCancel = () => {
      setDialogOpen(false);
    }
    const handleRequestDataSend = () => {
      setSubmitting(true);
      const data = {
        id: clientId,
        token: context.user?.token,
        type: requestType
      };
      model.sendCreateAssessmentRequest(dispatcher, data).unwrap().then(res => {
        setSubmitting(false);
        if(res.success) {
          if(res.data.type == "LOCAL") {
            dispatcher(openSnackbar({open: true, message: t('data_request_success'), variant: 'alert', alert: {color: 'success'}, close: false}));
            setTimeout(() => {
              window.location.reload(false);
            }, 2000);
          }
          else {
            dispatcher(openSnackbar({open: true, message: t('data_request_success_api'), variant: 'alert', alert: {color: 'success'}, close: false}));
          }
        }
        else {
          dispatcher(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
        }
        handleRequestDataCancel();
      });
    }
    const requestDataLabelLocal = <Stack direction={"row"} justifyContent="space-between" alignItems="center" spacing={1}>
      <Typography>{t('request_data_type_local')}</Typography>
      <Tooltip title={t('request_data_type_local_desc')}><InfoCircleOutlined /></Tooltip>
    </Stack>
    const requestDataLabelApi = <Stack direction={"row"} justifyContent="space-between" alignItems="center" spacing={1}>
    <Typography>{t('request_data_type_api')}</Typography>
    <Tooltip title={t('request_data_type_api_desc')}><InfoCircleOutlined /></Tooltip>
  </Stack>
    return(
        <>
          <Grid container spacing={1.25}>
            <Grid container spacing={0} direction="row" alignItems="right" sx={{marginBottom: 1, marginLeft: 1}} justifyContent="flex-start">
              <Button variant="shadow" color="info" onClick={requestData} disabled={!canRequestAssessment}>{t('request_data')}</Button>
              </Grid>
            </Grid>
          <ScrollX>
            <ReactTable
              columns={columns}
              data={items}
              page={filter.page}
              pageSize={filter.limit}
              sortBy={sortBy}
              search={null}
              rowCountTotal={assessmentData?.data?.numOfItems}
              getHeaderProps={(column) => column.getSortByToggleProps()}
              handlePageChange={handlePageChange} 
              handlePageSizeChange={handlePageSizeChange}
              handleSort={handleSort}
              handleSearch={null}
              topSpacing={0} />
          </ScrollX>

          <Dialog maxWidth="sm" fullWidth onClose={handleRequestDataCancel} open={dialogOpen} sx={{ '& .MuiDialog-paper': { p: 0 } }}>
            <DialogTitle>{t('request_data')}</DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
              <Box sx={{ mb: 2.5 }}>
                <Typography variant="p" color="textPrimary">{t('request_data_desc')}</Typography>
              </Box>
              <FormControl component="fieldset">
                <FormLabel id="demo-controlled-radio-buttons-group">{t('request_data_type')}</FormLabel>
                <RadioGroup 
                  value={requestType}
                  onChange={handleRequestTypeChange}
                  aria-label="request-data-type"
                  name="radio-buttons-group">
                  <FormControlLabel value="LOCAL" control={<Radio />} label={requestDataLabelLocal} disabled={!canRequestAssessmentLocal} />
                  <FormControlLabel value="API" control={<Radio />} label={requestDataLabelApi} disabled={!canRequestAssessmentApi} />
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item></Grid>
                <Grid item>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Button color="error" onClick={handleRequestDataCancel}>
                      {t('cancel')}
                    </Button>
                    <Button type="submit" variant="contained" disabled={isSubmitting} onClick={handleRequestDataSend}>
                      {t('save')}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>

        </>
      );
}