import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { faker } from '@faker-js/faker';

import { openSnackbar } from 'store/reducers/snackbar';

import { addClientRequest, editClientRequest } from 'store/reducers/client';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

import { ConnectedFocusError } from 'focus-formik-error'
import DashboardConfigurationData from 'model/dto/DashboardConfigurationData';
import GridTextInputItem from 'components/statosfera/form/GridTextInputItem';
import { useState } from 'react';
import GridFormSectionTitle from 'components/statosfera/form/GridFormSectionTitle';
import FormErrorText from 'components/statosfera/common/FormErrorText';
import CommonViewModel from 'model/viewmodel/CommonViewModel';
import { scopes } from 'config/scopes';

// constant
const getInitialValues = (customer, configuration) => {
  let defaultClientType = customer?.clientType ? customer.clientType : configuration.getSetting(DashboardConfigurationData.SETTING_DEFAULT_CLIENT_TYPE, DashboardConfigurationData.CLIENT_TYPE_PERSONAL);
  const defaultClientLanguage = configuration.getSetting(DashboardConfigurationData.SETTING_DEFAULT_LANGUAGE, "hu");
  const defaultClientCurrency = configuration.getSetting(DashboardConfigurationData.SETTING_DEFAULT_CURRENCY, "HUF");
  const defaultClientMonthlyPayment = configuration.getFormSetting(
    DashboardConfigurationData.FORM_CONFIGURATION_DEFAULT_MONTHLY_PAYMENT, 
    defaultClientType, 
    ''
  );
  const defaultClientMonthlyDeduction = configuration.getFormSetting(
    DashboardConfigurationData.FORM_CONFIGURATION_DEFAULT_MONTHLY_DEDUCTION, 
    defaultClientType, 
    0
  );
  const defaultClientMonthlyAddition = configuration.getFormSetting(
    DashboardConfigurationData.FORM_CONFIGURATION_DEFAULT_MONTHLY_ADDITION, 
    defaultClientType, 
    0
  );
  const defaultClientAddressCountryId = configuration.getFormSetting(DashboardConfigurationData.SETTING_DEFAULT_CLIENT_ADDRESS_COUNTRY_ID, defaultClientType, "hu");
  const defaultClientCitizenshipCountryId = configuration.getFormSetting(DashboardConfigurationData.SETTING_DEFAULT_CLIENT_CITIZENSHIP_COUNTRY_ID, defaultClientType, "hu");
  const defaultClientTaxResidencyCountryId = configuration.getFormSetting(DashboardConfigurationData.SETTING_DEFAULT_CLIENT_TAXRESIDENCY_COUNTRY_ID, defaultClientType, "hu");

  const newCustomer = {
    clientType: defaultClientType,
    firstName: '',
    lastName: '',
    legalName: '',
    taxNumber: '',
    email: '',
    phone: '',
    maxAllowedMonthlyPayment: defaultClientMonthlyPayment ? defaultClientMonthlyPayment: '',
    monthlyDeduction: defaultClientMonthlyDeduction,
    monthlyAddition: defaultClientMonthlyAddition,
    language: defaultClientLanguage,
    externalId: '',
    gender: '',
    citizenshipCountryId: defaultClientCitizenshipCountryId,
    taxResidencyCountryId: defaultClientTaxResidencyCountryId,
    netMonthlyIncome: '',
    incomeCurrency: defaultClientCurrency ? defaultClientCurrency : '',
    addressCountryId: defaultClientAddressCountryId,
    zip: '',
    city: '',
    street: '',
    dateOfBirth: null,
    educationLevel: '',
    familyStatus: '',
    householdSize: '',
    numberOfDependents: ''
  };

  if (customer) {
    
    const getData = (field, defaultValue = "") => {
      return field ? field : defaultValue; 
    }
    const getDataNumber = (field, defaultValue = "") => {
      return field && field > 0 ? field : defaultValue; 
    }

    newCustomer.id = customer.id;
    newCustomer.clientType = getData(customer.clientType, defaultClientType);
    newCustomer.firstName = getData(customer.firstName);
    newCustomer.lastName = getData(customer.lastName);
    newCustomer.legalName = getData(customer.legalName);
    newCustomer.taxNumber = getData(customer.taxNumber);
    newCustomer.email = getData(customer.email);
    newCustomer.phone = getData(customer.phone);
    newCustomer.maxAllowedMonthlyPayment = getData(customer.maxAllowedMonthlyPayment, defaultClientMonthlyPayment);
    newCustomer.monthlyDeduction = getData(customer.monthlyDeduction, defaultClientMonthlyDeduction);
    newCustomer.monthlyAddition = getData(customer.monthlyAddition, defaultClientMonthlyAddition);
    newCustomer.language = getData(customer.language, defaultClientLanguage);
    newCustomer.externalId = getData(customer.externalId);
    newCustomer.gender = getData(customer.gender);
    newCustomer.citizenshipCountryId = getData(customer.citizenshipCountryId);
    newCustomer.taxResidencyCountryId = getData(customer.taxResidencyCountryId);
    newCustomer.netMonthlyIncome = getDataNumber(customer.netMonthlyIncome);
    newCustomer.incomeCurrency = getData(customer.incomeCurrency);
    newCustomer.addressCountryId = getData(customer.addressCountryId);
    newCustomer.zip = getData(customer.zip);
    newCustomer.city = getData(customer.city);
    newCustomer.street = getData(customer.street);
    newCustomer.dateOfBirth = getData(customer.dateOfBirth, null);
    newCustomer.educationLevel = getData(customer.educationLevel);
    newCustomer.familyStatus = getData(customer.familyStatus);
    newCustomer.householdSize = getDataNumber(customer.householdSize);
    newCustomer.numberOfDependents = getDataNumber(customer.numberOfDependents);
    return newCustomer;
  }

  return newCustomer;
};

const phoneRules = /^\+(\d).{10,50}$/;

const isNotNilOrWhitespace = input => (input?.trim()?.length || 0) > 0;

const clientTypePersonal = DashboardConfigurationData.CLIENT_TYPE_PERSONAL;
const clientTypeBusiness = DashboardConfigurationData.CLIENT_TYPE_BUSINESS;
const clientTypeBoth = DashboardConfigurationData.CLIENT_TYPE_BOTH;

const AddClient = ({model, customer, onCancel, onSaved }) => {
  const theme = useTheme();
  const context = useAuth();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const isCreating = !customer;

  const availableCountries = [
    {name : "country_hungary", value: "hu"},
    {name : "country_czech", value: "cz"},
    {name : "country_croatia", value: "hr"},
    {name : "country_noneu", value: "noneu"},
  ];
  const availableCurrencies = [
    {name : "currency_huf", value: "HUF"},
    {name : "currency_czk", value: "CZK"},
    {name : "currency_eur", value: "EUR"}
  ];
  const availableLanguages = [
    {name : "lang_hu", value: "hu"},
    {name : "lang_cz", value: "cz"},
    {name : "lang_hr", value: "hr"},
    {name : "lang_en", value: "en"}
  ];

  const [configuration, setConfiguration] = useState(new DashboardConfigurationData(context.user?.configuration, DashboardConfigurationData.FORM_ID_ADD_CLIENT));

  const defaultClientCurrency = configuration.getSetting(DashboardConfigurationData.SETTING_DEFAULT_CURRENCY, "HUF");
  const allowedClientTypes = configuration.getSetting(DashboardConfigurationData.SETTING_ALLOWED_CLIENT_TYPES, clientTypeBoth);

  const getRequiredErrorMessages = (field) => {
    return t('field_is_required');
  }

  const testRequiredField = (field, clientType, defaultValue = false) => {
    return {
      is: clientType, 
      then: Yup.string()
        .nullable()
        .test("is-required", getRequiredErrorMessages(field), value => {
          let isRequired = configuration.isRequiredField(field, clientType, defaultValue);
          if(isRequired && isNotNilOrWhitespace(value)) {
            return true;
          }
          return !isRequired;
        })
    };
  }

  const testRequiredNumberField = (field, clientType, defaultValue = false, min = 0, max = 100000000) => {
    return {
      is: clientType, 
      then: Yup.number()
        .typeError(t('must_be_a_number'))
        .min(min, t('must_be_min_value', {min}))
        .max(max, t('must_be_max_value', {max}))
        .test("is-required", getRequiredErrorMessages(field), value => {
          let isRequired = configuration.isRequiredField(field, clientType, defaultValue);
          if(isRequired && (typeof value === 'number')) {
            return true;
          }
          return !isRequired;
        })
    };
  }

  const ClientSchema = Yup.object().shape({
    clientType: Yup.string()
      .required(t('client_type_is_required'))
      .oneOf([clientTypePersonal, clientTypeBusiness]),
    email: Yup.string()
      .max(255)
      .email(t('must_be_valid_email'))
      .when('clientType', testRequiredField("email", clientTypePersonal, true))
      .when('clientType', testRequiredField("email", clientTypeBusiness, true)),
    phone: Yup.string()
      .max(50)
      .matches(phoneRules, { message: t('phone_format_error') })
      .when('clientType', testRequiredField("phone", clientTypePersonal))
      .when('clientType', testRequiredField("phone", clientTypeBusiness)),
    lastName: Yup.string()
      .max(64)
      .when('clientType', testRequiredField("lastName", clientTypePersonal))
      .when('clientType', testRequiredField("lastName", clientTypeBusiness)),
    firstName: Yup.string()
      .max(64)
      .when('clientType', testRequiredField("firstName", clientTypePersonal))
      .when('clientType', testRequiredField("firstName", clientTypeBusiness)),
    legalName: Yup.string()
      .max(128)
      .when('clientType', testRequiredField("legalName", clientTypePersonal))
      .when('clientType', testRequiredField("legalName", clientTypeBusiness)),
    taxNumber: Yup.string()
      .max(128)
      .when('clientType', testRequiredField("taxNumber", clientTypePersonal))
      .when('clientType', testRequiredField("taxNumber", clientTypeBusiness)),
    gender: Yup.string()
      .when('clientType', testRequiredField("gender", clientTypePersonal))
      .when('clientType', testRequiredField("gender", clientTypeBusiness)),
    citizenshipCountryId: Yup.string()
      .when('clientType', testRequiredField("citizenshipCountryId", clientTypePersonal))
      .when('clientType', testRequiredField("citizenshipCountryId", clientTypeBusiness)),
    taxResidencyCountryId: Yup.string()
      .when('clientType', testRequiredField("taxResidencyCountryId", clientTypePersonal))
      .when('clientType', testRequiredField("taxResidencyCountryId", clientTypeBusiness)),
    netMonthlyIncome: Yup.number()
      .when('clientType', testRequiredNumberField("netMonthlyIncome", clientTypePersonal))
      .when('clientType', testRequiredNumberField("netMonthlyIncome", clientTypeBusiness)),
    incomeCurrency: Yup.string()
      .when('clientType', testRequiredField("incomeCurrency", clientTypePersonal))
      .when('clientType', testRequiredField("incomeCurrency", clientTypeBusiness)),
    addressCountryId: Yup.string()
      .when('clientType', testRequiredField("addressCountryId", clientTypePersonal))
      .when('clientType', testRequiredField("addressCountryId", clientTypeBusiness)),
    zip: Yup.string()
      .max(12)
      .when('clientType', testRequiredField("zip", clientTypePersonal))
      .when('clientType', testRequiredField("zip", clientTypeBusiness)),
    city: Yup.string()
      .max(64)
      .when('clientType', testRequiredField("city", clientTypePersonal))
      .when('clientType', testRequiredField("city", clientTypeBusiness)),
    street: Yup.string()
      .max(255)
      .when('clientType', testRequiredField("street", clientTypePersonal))
      .when('clientType', testRequiredField("street", clientTypeBusiness)),
    dateOfBirth: Yup.string()
      .when('clientType', testRequiredField("dateOfBirth", clientTypePersonal))
      .when('clientType', testRequiredField("dateOfBirth", clientTypeBusiness)),    
    educationLevel: Yup.string()
      .when('clientType', testRequiredField("educationLevel", clientTypePersonal))
      .when('clientType', testRequiredField("educationLevel", clientTypeBusiness)),    
    familyStatus: Yup.string()
      .when('clientType', testRequiredField("familyStatus", clientTypePersonal))
      .when('clientType', testRequiredField("familyStatus", clientTypeBusiness)),    
    householdSize: Yup.number()
      .when('clientType', testRequiredNumberField("householdSize", clientTypePersonal, false, 1, 100))
      .when('clientType', testRequiredNumberField("householdSize", clientTypeBusiness, false, 1, 100)),
    numberOfDependents: Yup.number()
      .when('clientType', testRequiredNumberField("numberOfDependents", clientTypePersonal, false, 0, 100))
      .when('clientType', testRequiredNumberField("numberOfDependents", clientTypeBusiness, false, 0, 100)),
    maxAllowedMonthlyPayment: Yup.number()
      .when('clientType', testRequiredNumberField(
          "maxAllowedMonthlyPayment", 
          clientTypePersonal, 
          true, 
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MIN_MONTHLY_PAYMENT, clientTypePersonal, 500),
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MAX_MONTHLY_PAYMENT, clientTypePersonal, 1000000)
      ))
      .when('clientType', testRequiredNumberField(
          "maxAllowedMonthlyPayment", 
          clientTypeBusiness, 
          true, 
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MIN_MONTHLY_PAYMENT, clientTypeBusiness, 500),
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MAX_MONTHLY_PAYMENT, clientTypeBusiness, 1000000)
      )),
    monthlyDeduction: Yup.number()
      .when('clientType', testRequiredNumberField(
          "monthlyDeduction", 
          clientTypePersonal, 
          false, 
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MIN_MONTHLY_DEDUCTION, clientTypePersonal, 0),
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MAX_MONTHLY_DEDUCTION, clientTypePersonal, 1000000)
        ))
      .when('clientType', testRequiredNumberField(
          "monthlyDeduction", 
          clientTypeBusiness, 
          false, 
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MIN_MONTHLY_DEDUCTION, clientTypeBusiness, 0),
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MAX_MONTHLY_DEDUCTION, clientTypeBusiness, 1000000)
      )),
    monthlyAddition: Yup.number()
      .when('clientType', testRequiredNumberField(
          "monthlyAddition", 
          clientTypePersonal, 
          false, 
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MIN_MONTHLY_ADDITION, clientTypePersonal, 0),
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MAX_MONTHLY_ADDITION, clientTypePersonal, 1000000)
        ))
      .when('clientType', testRequiredNumberField(
          "monthlyAddition", 
          clientTypeBusiness, 
          false, 
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MIN_MONTHLY_ADDITION, clientTypeBusiness, 0),
          configuration.getFormSetting(DashboardConfigurationData.FORM_CONFIGURATION_MAX_MONTHLY_ADDITION, clientTypeBusiness, 1000000)
      )),
    externalId: Yup.string()
      .max(255)
      .when('clientType', testRequiredField("externalId", clientTypePersonal))
      .when('clientType', testRequiredField("externalId", clientTypeBusiness)),
    language: Yup.string()
      .when('clientType', testRequiredField("language", clientTypePersonal, true))
      .when('clientType', testRequiredField("language", clientTypeBusiness, true))
  });

  const formik = useFormik({
    initialValues: getInitialValues(customer, configuration),
    validationSchema: ClientSchema,
    onSubmit: (values, { setSubmitting }) => {
        values.token = context.user?.token;
        if(!values.educationLevel) {
          values.educationLevel = null;
        }
        if(!values.familyStatus) {
          values.familyStatus = null;
        }
        if(!values.gender) {
          values.gender = null;
        }
        if(!values.householdSize) {
          values.householdSize = null;
        }
        if(!values.netMonthlyIncome) {
          values.netMonthlyIncome = null;
        }
        if(!values.numberOfDependents) {
          values.numberOfDependents = null;
        }
        if(!values.citizenshipCountryId) {
          values.citizenshipCountryId = null;
        }
        if(!values.addressCountryId) {
          values.addressCountryId = null;
        }
        if(!values.incomeCurrency) {
          values.incomeCurrency = null;
        }
        if(!values.taxResidencyCountryId) {
          values.taxResidencyCountryId = null;
        }
        if (customer) {
          values.id = customer.id;
          dispatch(editClientRequest(values)).unwrap().then(res => {
            onClientSavedResult(res, true, setSubmitting);
          });
        } 
        else {
          dispatch(addClientRequest(values)).unwrap().then(res => {
            onClientSavedResult(res, false, setSubmitting);
          });
        }
      }
  });

  const onClientSavedResult = (res, isEdited, setSubmitting) => {
    setSubmitting(false);
    if(res.success) {
      dispatch(openSnackbar({open: true, message: isEdited ? t('client_edited') : t('client_added'), variant: 'alert', alert: {color: 'success'}, close: false}));
      if(onSaved) {
        onSaved(res?.data?.clientId);
      }
      onCancel();
    }
    else {
      if(res.errorCode == "mitigate-0002") {
        dispatch(openSnackbar({open: true, message: t('client_already_exists_with_email'), variant: 'alert', alert: {color: 'error'}, close: false}));
        return;
      }
      dispatch(openSnackbar({open: true, message: 'Error: '+res.messages?.[0], variant: 'alert', alert: {color: 'error'}, close: false}));
    }
  }

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  const hiddenFields = configuration.getFormFields("hiddenFields", formik.values.clientType, []);
  const allFields = configuration.getFormFields("allFields", formik.values.clientType, []);
  const isFieldHidden = (field) => {
    let isHidden = hiddenFields.filter(i => i.id == field).length > 0;
    if(!isHidden) {
      isHidden = !(allFields.filter(i => i.id == field).length > 0);
    }
    return {
      display: isHidden ? 'none' : 'block'
    };
  }

  const isHiddenClientType = (clientType) => {
    let isHidden = false;
    if(allowedClientTypes == "PERSONAL" && clientType == "BUSINESS") {
      isHidden = true;
    }
    else if(allowedClientTypes == "BUSINESS" && clientType == "PERSONAL") {
      isHidden = true;
    }
    return {
      display: isHidden ? 'none' : 'block'
    };
  }
  
  const handleBirthdayChange = (newDate) => {
    formik.setFieldValue("dateOfBirth", newDate);
  }

  const shouldShowGenerateButton = model.hasScope(scopes.SCOPE_MITIGATE_SCORE_DETAILS);

  const onGenerateRandomData = () => {
    let firstName = faker.person.firstName();
    let lastName = faker.person.lastName();
    let email = faker.internet.email({firstName, lastName, provider: "statosfera.com"}).toLowerCase();
    let phone = "+3630"+faker.number.int({min: 1111111, max: 9999999});
    getRandomValue('client-first-name', firstName);
    getRandomValue('client-last-name', lastName);
    getRandomValue('client-legal-name', firstName+" "+lastName);
    getRandomValue('client-taxnumber', faker.number.int({min: 111111, max: 999999}));
    getRandomValue('client-email', email);
    getRandomValue('client-phone', phone);
    getRandomValue('client-householdsize', faker.number.int({min: 1, max: 2}));
    getRandomValue('client-numberofdependents', faker.number.int({min: 0, max: 4}));
    let y = faker.number.int({min: 1950, max: 2005});
    let m = faker.number.int({min: 1, max: 12});
    if(m < 10) {
      m = "0"+m;
    }
    let d = faker.number.int({min: 1, max: 30});
    if(d < 10) {
      d = "0"+d;
    }
    model.setNativeValue(document.getElementById('client-birth-date-container').getElementsByTagName('input')[0], y+"-"+m+"-"+d);
    const genders = ['MALE', 'FEMALE'];
    getRandomSelectValue("gender", genders);
    const educationLevels = ['BASIC', 'SECONDARY', 'COLLEGE'];
    getRandomSelectValue("educationLevel", educationLevels);
    const familyStatuses = ['SINGLE', 'MARRIED', 'DIVORCED', 'PARTNERSHIP'];
    getRandomSelectValue("familyStatus", familyStatuses);
    //Place dependent
    let countries = ['hu'];
    if(defaultClientCurrency == "CZK") {
      countries = ['cz'];
    }
    getRandomSelectValue("citizenshipCountryId", countries);
    let taxResidencyCountryId = getRandomSelectValue("taxResidencyCountryId", countries);
    getRandomSelectValue("addressCountryId", countries);
    
    if(taxResidencyCountryId == "hu") {
      getRandomValue('client-netincome', faker.number.int({min: 100000, max: 800000}));
      getRandomValue('client-maxAllowedMonthlyPayment', faker.number.int({min: 4000, max: 50000}));
      getRandomValue("client-zip", ["1074", "1706"]);
      getRandomValue("client-city", ["Budapest", "Debrecen", "Miskolc", "Szeged", "Pécs", "Győr"]);
      getRandomValue("client-street", ["Kossuth Lajos utca 1", "Széchenyi utca 2", "Petődi utca 3"]);
    }
    else if(taxResidencyCountryId == "cz") {
      getRandomValue('client-netincome', faker.number.int({min: 1000, max: 10000}));
      getRandomValue('client-maxAllowedMonthlyPayment', faker.number.int({min: 200, max: 5000}));
      getRandomValue("client-zip", ["100 00", "102 00", "103 00", "272 01", "273 28", "435 43"]);
      getRandomValue("client-city", ["Prague", "Brno", "Ostrava", "Plzeň", "Liberec"]);
      getRandomValue("client-street", ["Hlavní třída 1", "Ulice 28. října 2", "Mírová ulice 3"]);
    }
    else if(taxResidencyCountryId == "hr") {
      getRandomValue('client-netincome', faker.number.int({min: 500, max: 5000}));
      getRandomValue('client-maxAllowedMonthlyPayment', faker.number.int({min: 100, max: 1000}));
      getRandomValue("client-zip", ["10110", "10000", "10090"]);
      getRandomValue("client-city", ["Zagreb", "Zadar", "Split", "Rijeka", "Osijek"]);
      getRandomValue("client-street", ["Lastovska", "Prisavlje", "Bobovacka"]);
    }
    else {
      getRandomValue('client-netincome', faker.number.int({min: 1000, max: 10000}));
      getRandomValue('client-maxAllowedMonthlyPayment', faker.number.int({min: 200, max: 1000}));
    }
  }

  const getRandomValue = (id, values) => {
    if(!values) {
      return;
    }
    let val = Array.isArray(values) ? values[faker.number.int({ min: 0, max: values.length - 1 })] : values;
    model.setNativeValue(document.getElementById(id), val);
    return val;
  }

  const getRandomSelectValue = (id, values) => {
    let val = values[faker.number.int({ min: 0, max: values.length - 1 })];
    model.setNativeValue(document.getElementsByName(id)[0], val);
    return val;
  }

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>{customer ? t('edit_client') : t('new_client')}</DialogTitle>
          <Divider />
          <DialogContent sx={{ p: 2.5 }}>
            {shouldShowGenerateButton && 
            <Button variant="contained" sx={{mb: 3}} onClick={onGenerateRandomData}>Generate data</Button>
            }
            <ConnectedFocusError />
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel id="client-type-label" htmlFor="client-type">{t('client_type')}*</InputLabel>
                      <Select
                        labelId="client-type-label"
                        id="client-type"
                        label="Type"
                        {...getFieldProps('clientType')}
                        error={Boolean(touched.clientType && errors.clientType)}
                      >
                        <MenuItem value={DashboardConfigurationData.CLIENT_TYPE_PERSONAL} sx={isHiddenClientType('PERSONAL')}>{t('client_type_personal')}</MenuItem>
                        <MenuItem value={DashboardConfigurationData.CLIENT_TYPE_BUSINESS} sx={isHiddenClientType('BUSINESS')}>{t('client_type_business')}</MenuItem>
                      </Select>
                      <FormErrorText formik={formik} fieldId="clientType" />
                    </Stack>
                  </Grid>

                  <GridFormSectionTitle label={t('personal_details')} sx={isFieldHidden('firstName')} />

                  <GridTextInputItem 
                    id="client-first-name" 
                    label={t('first_name')} 
                    placeholder={t('enter_first_name')} 
                    fieldProps={getFieldProps('firstName')} 
                    touched={touched.firstName} 
                    errors={errors.firstName}
                    xs={6}
                    sx={isFieldHidden('firstName')} />

                  <GridTextInputItem 
                    id="client-last-name" 
                    label={t('last_name')} 
                    placeholder={t('enter_last_name')} 
                    fieldProps={getFieldProps('lastName')} 
                    touched={touched.lastName} 
                    errors={errors.lastName}
                    xs={6}
                    sx={isFieldHidden('lastName')} />

                  <GridFormSectionTitle label={t('business_entity_details')} sx={isFieldHidden('legalName')} />

                  <GridTextInputItem 
                    id="client-legal-name" 
                    label={t('legal_name')} 
                    placeholder={t('enter_legal_name')} 
                    fieldProps={getFieldProps('legalName')} 
                    touched={touched.legalName} 
                    errors={errors.legalName}
                    sx={isFieldHidden('legalName')} />

                  <GridTextInputItem 
                    id="client-taxnumber" 
                    label={t('tax_number')} 
                    placeholder={t('enter_tax_number')} 
                    fieldProps={getFieldProps('taxNumber')} 
                    touched={touched.taxNumber} 
                    errors={errors.taxNumber}
                    sx={isFieldHidden('taxNumber')} />

                  <GridFormSectionTitle label={t('contact_data')} />
                
                  <GridTextInputItem 
                    id="client-email" 
                    label={t('email')+'*'} 
                    placeholder={t('enter_email_address')} 
                    fieldProps={getFieldProps('email')}
                    disabled={!isCreating} 
                    touched={touched.email} 
                    errors={errors.email}
                    sx={isFieldHidden('email')} />

                  <GridTextInputItem 
                    id="client-phone" 
                    label={t('phone')} 
                    placeholder={t('enter_phone_number')} 
                    fieldProps={getFieldProps('phone')}
                    touched={touched.phone} 
                    errors={errors.phone}
                    sx={isFieldHidden('phone')} />

                  <GridFormSectionTitle label={t('social_data_personal')} sx={isFieldHidden('gender')} />

                  <Grid item xs={3} sx={isFieldHidden('gender')}>
                    <Stack spacing={1.25}>
                      <InputLabel id="gender-label" htmlFor="gender">{t('gender')}</InputLabel>
                      <Select
                        labelId="gender-label"
                        id="gender"
                        label="gender"
                        {...getFieldProps('gender')}
                        error={Boolean(touched.gender && errors.gender)}
                      >
                        <MenuItem value={'MALE'}>{t('gender_male')}</MenuItem>
                        <MenuItem value={'FEMALE'}>{t('gender_female')}</MenuItem>
                        <MenuItem value={'OTHER'}>{t('gender_other')}</MenuItem>
                      </Select>
                      <FormErrorText formik={formik} fieldId="gender" />
                    </Stack>
                  </Grid>

                  <Grid item xs={9} sx={isFieldHidden('citizenshipCountryId')}>
                    <Stack spacing={1.25}>
                      <InputLabel id="citizenship-label" htmlFor="citizenship">{t('citizenship')}</InputLabel>
                      <Select
                        labelId="citizenship-label"
                        id="citizenship"
                        label="citizenship"
                        {...getFieldProps('citizenshipCountryId')}
                        error={Boolean(touched.citizenshipCountryId && errors.citizenshipCountryId)}
                      >
                        {availableCountries.map(s => {
                          return <MenuItem key={'country_item_citizenship_'+s.value} value={s.value}>{t(s.name)}</MenuItem>
                        })}
                      </Select>
                      <FormErrorText formik={formik} fieldId="citizenshipCountryId" />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sx={isFieldHidden('dateOfBirth')}>
                    <Stack id="client-birth-date-container" spacing={1.25}>
                      <InputLabel htmlFor="client-birth-date">{t('birth_date')}</InputLabel>
                      <DesktopDatePicker
                        label=""
                        inputFormat="yyyy-MM-dd"
                        value={formik.values.dateOfBirth}
                        onChange={handleBirthdayChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <FormErrorText formik={formik} fieldId="dateOfBirth" />
                    </Stack>
                  </Grid>

                  <GridFormSectionTitle label={t('social_data_income')} sx={isFieldHidden('gender')} />

                  <Grid item xs={12} sx={isFieldHidden('taxResidencyCountryId')}>
                    <Stack spacing={1.25}>
                      <InputLabel id="taxresidency-label" htmlFor="taxresidency">{t('taxresidency')}</InputLabel>
                      <Select
                        labelId="taxresidency-label"
                        id="taxresidency"
                        label="taxresidency"
                        {...getFieldProps('taxResidencyCountryId')}
                        error={Boolean(touched.taxResidencyCountryId && errors.taxResidencyCountryId)}
                      >
                        {availableCountries.map(s => {
                          return <MenuItem key={'country_item_taxresidency_'+s.value} value={s.value}>{t(s.name)}</MenuItem>
                        })}
                      </Select>
                      <FormErrorText formik={formik} fieldId="taxResidencyCountryId" />
                    </Stack>
                  </Grid>
                  
                  <GridTextInputItem 
                    id="client-netincome" 
                    label={t('net_monthly_income')} 
                    placeholder={t('enter_net_monthly_income')} 
                    fieldProps={getFieldProps('netMonthlyIncome')}
                    touched={touched.netMonthlyIncome} 
                    errors={errors.netMonthlyIncome}
                    xs={9}
                    sx={isFieldHidden('netMonthlyIncome')} />

                  <Grid item xs={3} sx={isFieldHidden('netMonthlyIncomeCurrency')}>
                    <Stack spacing={1.25}>
                      <InputLabel id="incomecurrency-label" htmlFor="incomecurrency">{t('income_currency')}</InputLabel>
                      <Select
                        labelId="incomecurrency-label"
                        id="incomecurrency"
                        label="incomecurrency"
                        {...getFieldProps('incomeCurrency')}
                        error={Boolean(touched.incomeCurrency && errors.incomeCurrency)}
                      >
                        {availableCurrencies.map(s => {
                          return <MenuItem key={'currency_item_income_'+s.value} value={s.value}>{t(s.name)}</MenuItem>
                        })}
                      </Select>
                      <FormErrorText formik={formik} fieldId="incomeCurrency" />
                    </Stack>
                  </Grid>

                  <GridFormSectionTitle label={t('social_data_other')} sx={isFieldHidden('educationLevel')} />

                  <Grid item xs={6} sx={isFieldHidden('educationLevel')}>
                    <Stack spacing={1.25}>
                      <InputLabel id="educationlevel-label" htmlFor="educationlevel">{t('education_level')}</InputLabel>
                      <Select
                        labelId="educationlevel-label"
                        id="educationlevel"
                        label="educationlevel"
                        {...getFieldProps('educationLevel')}
                        error={Boolean(touched.educationLevel && errors.educationLevel)}
                      >
                        <MenuItem value={'BASIC'}>{t('education_level_basic')}</MenuItem>
                        <MenuItem value={'APPRENTICESHIP'}>{t('education_level_apprenticeship')}</MenuItem>
                        <MenuItem value={'SECONDARY'}>{t('education_level_secondary')}</MenuItem>
                        <MenuItem value={'COLLEGE'}>{t('education_level_college')}</MenuItem>
                        <MenuItem value={'UNIVERSITY'}>{t('education_level_university')}</MenuItem>
                      </Select>
                      <FormErrorText formik={formik} fieldId="educationLevel" />
                    </Stack>
                  </Grid>

                  <Grid item xs={6} sx={isFieldHidden('familyStatus')}>
                    <Stack spacing={1.25}>
                      <InputLabel id="familystatus-label" htmlFor="familystatus">{t('family_status')}</InputLabel>
                      <Select
                        labelId="familystatus-label"
                        id="familystatus"
                        label="familystatus"
                        {...getFieldProps('familyStatus')}
                        error={Boolean(touched.familyStatus && errors.familyStatus)}
                      >
                        <MenuItem value={'SINGLE'}>{t('family_status_single')}</MenuItem>
                        <MenuItem value={'MARRIED'}>{t('family_status_married')}</MenuItem>
                        <MenuItem value={'PARTNERSHIP'}>{t('family_status_partnership')}</MenuItem>
                        <MenuItem value={'DIVORCED'}>{t('family_status_divorced')}</MenuItem>
                        <MenuItem value={'WIDOWED'}>{t('family_status_widowed')}</MenuItem>
                      </Select>
                      <FormErrorText formik={formik} fieldId="familyStatus" />
                    </Stack>
                  </Grid>

                  <GridTextInputItem 
                    id="client-householdsize" 
                    label={t('householdsize')} 
                    placeholder={t('enter_householdsize')} 
                    fieldProps={getFieldProps('householdSize')}
                    touched={touched.householdSize} 
                    errors={errors.householdSize}
                    xs={6}
                    sx={isFieldHidden('householdSize')} />

                  <GridTextInputItem 
                    id="client-numberofdependents" 
                    label={t('number_of_dependents')} 
                    placeholder={t('enter_number_of_dependents')} 
                    fieldProps={getFieldProps('numberOfDependents')}
                    touched={touched.numberOfDependents} 
                    errors={errors.numberOfDependents}
                    xs={6}
                    sx={isFieldHidden('numberOfDependents')} />

                  <GridFormSectionTitle label={t('address')} sx={isFieldHidden('addressCountryId')} />

                  <Grid item xs={12} sx={isFieldHidden('addressCountryId')}>
                    <Stack spacing={1.25}>
                      <InputLabel id="addressCountry-label" htmlFor="addressCountry">{t('address_country')}</InputLabel>
                      <Select
                        labelId="addressCountry-label"
                        id="addressCountry"
                        label="addressCountry"
                        {...getFieldProps('addressCountryId')}
                        error={Boolean(touched.addressCountryId && errors.addressCountryId)}
                      >
                        {availableCountries.map(s => {
                          return <MenuItem key={'country_item_address_'+s.value} value={s.value}>{t(s.name)}</MenuItem>
                        })}
                      </Select>
                      <FormErrorText formik={formik} fieldId="addressCountryId" />
                    </Stack>
                  </Grid>

                  <GridTextInputItem 
                    id="client-zip" 
                    label={t('zip_code')} 
                    placeholder={t('enter_zip_code')} 
                    fieldProps={getFieldProps('zip')}
                    touched={touched.zip} 
                    errors={errors.zip}
                    xs={3}
                    sx={isFieldHidden('zip')} />

                  <GridTextInputItem 
                    id="client-city" 
                    label={t('city')} 
                    placeholder={t('enter_city')} 
                    fieldProps={getFieldProps('city')}
                    touched={touched.city} 
                    errors={errors.city}
                    xs={9}
                    sx={isFieldHidden('city')} />

                  <GridTextInputItem 
                    id="client-street" 
                    label={t('street')} 
                    placeholder={t('enter_street')} 
                    fieldProps={getFieldProps('street')}
                    touched={touched.street} 
                    errors={errors.street}
                    sx={isFieldHidden('street')} />
                    
                  <GridFormSectionTitle label={t('other_settings')} />

                  <GridTextInputItem 
                    id="client-maxAllowedMonthlyPayment" 
                    label={t('monthly_payment', {currency: defaultClientCurrency})+'*'} 
                    placeholder={t('enter_monthly_payment', {currency: defaultClientCurrency})} 
                    fieldProps={getFieldProps('maxAllowedMonthlyPayment')}
                    touched={touched.maxAllowedMonthlyPayment} 
                    errors={errors.maxAllowedMonthlyPayment}
                    sx={isFieldHidden('maxAllowedMonthlyPayment')} />

                  <GridTextInputItem 
                    id="client-monthlyDeduction" 
                    label={t('monthly_fixed_deduction', {currency: defaultClientCurrency})} 
                    placeholder={t('enter_monthly_deduction', {currency: defaultClientCurrency})} 
                    fieldProps={getFieldProps('monthlyDeduction')}
                    touched={touched.monthlyDeduction} 
                    errors={errors.monthlyDeduction}
                    sx={isFieldHidden('monthlyDeduction')} />

                  <GridTextInputItem 
                    id="client-monthlyAddition" 
                    label={t('monthly_fixed_addition', {currency: defaultClientCurrency})} 
                    placeholder={t('enter_monthly_addition', {currency: defaultClientCurrency})} 
                    fieldProps={getFieldProps('monthlyAddition')}
                    touched={touched.monthlyAddition} 
                    errors={errors.monthlyAddition}
                    sx={isFieldHidden('monthlyAddition')} />

                  <Grid item xs={12} sx={isFieldHidden('language')}>
                    <Stack spacing={1.25}>
                      <InputLabel id="language-label" htmlFor="language">{t('language')+'*'}</InputLabel>
                      <Select
                        labelId="language-label"
                        id="language"
                        label="Language"
                        {...getFieldProps('language')}
                        error={Boolean(touched.language && errors.language)}
                      >
                        {availableLanguages.map(s => {
                          return <MenuItem key={'language_item_'+s.value} value={s.value}>{t(s.name)}</MenuItem>
                        })}
                      </Select>
                      <FormErrorText formik={formik} fieldId="language" />
                    </Stack>
                  </Grid>

                  <GridTextInputItem 
                    id="client-externalId" 
                    label={t('external_id')} 
                    placeholder={t('enter_external_id')} 
                    fieldProps={getFieldProps('externalId')}
                    touched={touched.externalId} 
                    errors={errors.externalId}
                    sx={isFieldHidden('externalId')} />

                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={onCancel}>
                    {t('cancel')}
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    {customer ? t('edit') : t('add')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
};

AddClient.propTypes = {
  customer: PropTypes.any,
  onCancel: PropTypes.func
};

export default AddClient;
