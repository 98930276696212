import axios from 'axios';
import { config as apiConfig, selectedProfile } from '../config/config';

const axiosInstancePublicApi = axios.create({
    baseURL: apiConfig[selectedProfile].PUBLIC_API_BASE_URL
});

const axiosInstanceAuthApi = axios.create({
    baseURL: apiConfig[selectedProfile].AUTH_API_BASE_URL
});

function getBasicHeaders(addClientId = false, token = null) {
    const headers = {
        "Content-Type": "application/json"
    };
    if(addClientId) {
        headers["X-Request-Client-ID"] = apiConfig[selectedProfile].OAUTH_CLIENT_ID;
    }
    if(token) {
        headers["Authorization"] = "Bearer "+token;
    }
    headers["X-Request-Test"] = "Test";
    return headers;
}

function getBasicOptions(addClientId = false, token = null) {
    return {
        headers: getBasicHeaders(addClientId, token),
        validateStatus: false
    }
}

function getMultipartOptions(addClientId = false, token = null) {
    let basicHeaders = getBasicHeaders(addClientId, token);
    basicHeaders["Content-Type"] = "multipart/form-data";
    return {
        headers: basicHeaders,
        validateStatus: false
    }
}

function getPaginationHeaders(token, paginationData) {
    let basicHeaders = getBasicHeaders(false, token);
    basicHeaders["X-Request-Pagination-Data"] = JSON.stringify(paginationData.getHeaderData());
    return {
        headers: basicHeaders,
        validateStatus: false
    }
}

//API requests
async function loginAdmin(email, password) {
    const data = {email: email, password : password};
    return await axiosInstanceAuthApi.post("/user/signin/dashboard", JSON.stringify(data), getBasicOptions(true));
}

async function forgetPassword(email, lang) {
    const data = {email: email, lang: lang, product: 'MITIGATE'};
    return await axiosInstanceAuthApi.post("/user/request-password", JSON.stringify(data), getBasicOptions(true));
}

async function getDashboard(payload) {
    const {token, timestamp} = payload;
    return await axiosInstancePublicApi.get("/dashboard", getBasicOptions(false, token));
}

async function getBanks(payload) {
    const {token, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/bank", getBasicOptions(false, token));
}

async function getDashboardConfiguration(payload) {
    const {token, timestamp} = payload;
    return await axiosInstancePublicApi.get("/dashboard/configuration", getBasicOptions(false, token));
}

async function getProfile(payload) {
    const {token, timestamp} = payload;
    return await axiosInstancePublicApi.get("/dashboard/profile", getBasicOptions(false, token));
}

async function getProfileBasic(payload) {
    const {token, timestamp} = payload;
    return await axiosInstancePublicApi.get("/dashboard/profile/basic", getBasicOptions(false, token));
}

async function getEvents(payload) {
    const {token, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/dashboard/profile/events", getPaginationHeaders(token, paginationData));
}

async function getCommunications(payload) {
    const {token, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/dashboard/profile/communications", getPaginationHeaders(token, paginationData));
}

async function updateProfile(token, data) {
    return await axiosInstancePublicApi.patch("/dashboard/profile", JSON.stringify(data), getBasicOptions(false, token));
}

async function changePassword(token, data) {
    return await axiosInstancePublicApi.post("/dashboard/profile/password", JSON.stringify(data), getBasicOptions(false, token));
} 

async function getUsers(payload) {
    const {token, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/user", getPaginationHeaders(token, paginationData));
}

async function addUser(token, data) {
    return await axiosInstancePublicApi.post("/mitigate/admin/user", JSON.stringify(data), getBasicOptions(false, token));
}

async function editUser(token, id, data) {
    return await axiosInstancePublicApi.post("/mitigate/admin/user/"+id, JSON.stringify(data), getBasicOptions(false, token));
}

async function deleteUser(token, id) {
    return await axiosInstancePublicApi.delete("/mitigate/admin/user/"+id, getBasicOptions(false, token));
}

async function blockUser(token, id) {
    return await axiosInstancePublicApi.post("/mitigate/admin/user/"+id+"/block", null, getBasicOptions(false, token));
}

async function getGroups(payload) {
    const {token, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/group", getPaginationHeaders(token, paginationData));
}

async function addGroup(token, data) {
    return await axiosInstancePublicApi.post("/mitigate/admin/group", JSON.stringify(data), getBasicOptions(false, token));
}

async function editGroup(token, groupId, data) {
    return await axiosInstancePublicApi.post("/mitigate/admin/group/"+groupId, JSON.stringify(data), getBasicOptions(false, token));
}

async function deleteGroup(token, groupId) {
    return await axiosInstancePublicApi.delete("/mitigate/admin/group/"+groupId, getBasicOptions(false, token));
}

async function exportUsers(payload) {
    const {token, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.post("/mitigate/admin/export/user", null, getPaginationHeaders(token, paginationData));
}

async function getClients(payload) {
    const {token, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client", getPaginationHeaders(token, paginationData));
}

async function exportClients(payload) {
    const {token, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.post("/mitigate/admin/export/client", null, getPaginationHeaders(token, paginationData));
}

async function addClient(token, data) {
    return await axiosInstancePublicApi.post("/mitigate/admin/client", JSON.stringify(data), getBasicOptions(false, token));
}

async function editClient(token, clientId, data) {
    return await axiosInstancePublicApi.post("/mitigate/admin/client/"+clientId, JSON.stringify(data), getBasicOptions(false, token));
}

async function deleteClient(token, clientId) {
    return await axiosInstancePublicApi.delete("/mitigate/admin/client/"+clientId, getBasicOptions(false, token));
}

async function changeClientStatus(token, clientId, data) {
    return await axiosInstancePublicApi.post("/mitigate/admin/client/"+clientId+"/status", JSON.stringify(data), getBasicOptions(false, token));
}

async function getClientDetails(payload) {
    const {token, clientId, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId, getBasicOptions(false, token));
}

async function getClientDetailsEdit(payload) {
    const {token, clientId, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/edit", getBasicOptions(false, token));
}

async function getClientData(payload) {
    const {token, clientId, timestamp, period, assessmentId} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/data?type="+period+"&id="+assessmentId, getBasicOptions(false, token));
}

async function getClientAccounts(payload) {
    const {token, clientId, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/accounts", getPaginationHeaders(token, paginationData));
}

async function getClientTransactions(payload) {
    const {token, clientId, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/transactions", getPaginationHeaders(token, paginationData));
}

async function getClientScans(payload) {
    const {token, clientId, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/scan", getPaginationHeaders(token, paginationData));
}

async function getClientScanDetails(payload) {
    const {token, clientId, scanId, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/scan/"+scanId, getBasicOptions(false, token));
}

async function addClientScan(payload) {
    const {token, clientId, bankId, label, fileInput, fileName} = payload;
    const form = new FormData();
    form.append('label', label);
    form.append('fileName', fileName);
    form.append('bankId', bankId);
    form.append('file', fileInput);
    return await axiosInstancePublicApi.post("/mitigate/admin/client/"+clientId+"/scan", form, getMultipartOptions(false, token));
}

async function getClientScanDataDetails(payload) {
    const {token, clientId, scanId, dataId, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/scan/"+scanId+"/data/"+dataId, getBasicOptions(false, token));
}

async function deleteScan(token, clientId, scanId) {
    return await axiosInstancePublicApi.delete("/mitigate/admin/client/"+clientId+"/scan/"+scanId, getBasicOptions(false, token));
}

async function getClientAssessmentHistory(payload) {
    const {token, clientId, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/assessments", getPaginationHeaders(token, paginationData));
}

async function getClientEvents(payload) {
    const {token, clientId, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/events", getPaginationHeaders(token, paginationData));
}

async function getClientCommunications(payload) {
    const {token, clientId, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/communications", getPaginationHeaders(token, paginationData));
}

async function getClientSessions(payload) {
    const {token, clientId, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/sessions", getPaginationHeaders(token, paginationData));
}

async function getClientSessionLogs(payload) {
    const {token, clientId, sessionId, paginationData, timestamp} = payload;
    return await axiosInstancePublicApi.get("/mitigate/admin/client/"+clientId+"/sessions/"+sessionId, getPaginationHeaders(token, paginationData));
}

async function generateClientAccessToken(payload) {
    const {token, clientId, timestamp, sendEmail, sendSms} = payload;
    const data = {clientId, sendEmail: sendEmail, sendSms: sendSms};
    return await axiosInstanceAuthApi.post("/mitigate/client/signin", JSON.stringify(data), getBasicOptions(false, token));
}

async function requestAssessment(token, clientId, data) {
    return await axiosInstancePublicApi.post("/mitigate/admin/client/"+clientId+"/data", JSON.stringify(data), getBasicOptions(false, token));
}

async function exportClientCompanyData(token, clientId, data) {
    return await axiosInstancePublicApi.post("/mitigate/admin/export/company_data/"+clientId, JSON.stringify(data), getBasicOptions(false, token));
}

export {
    loginAdmin, 
    forgetPassword, 
    getDashboard,
    getBanks,
    getDashboardConfiguration,
    getProfile, 
    getProfileBasic, 
    getEvents,
    getCommunications, 
    updateProfile, 
    changePassword, 
    getUsers,
    addUser,
    editUser,
    deleteUser,
    blockUser,
    getGroups,
    addGroup,
    editGroup,
    deleteGroup,
    exportUsers,
    getClients, 
    exportClients, 
    addClient, 
    editClient, 
    deleteClient, 
    changeClientStatus, 
    getClientDetails, 
    getClientDetailsEdit,
    getClientData, 
    getClientAccounts,
    getClientTransactions, 
    getClientScans, 
    getClientScanDetails,
    addClientScan,
    getClientScanDataDetails,
    deleteScan,
    getClientAssessmentHistory,
    getClientEvents,
    getClientCommunications,
    getClientSessions,
    getClientSessionLogs,
    generateClientAccessToken,
    requestAssessment,
    exportClientCompanyData
}